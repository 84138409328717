import React from "react";
import styled from "styled-components";
import { colors } from "../../../../components/ui-kit/colors";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
    text-align: center;
    //min-height: 100px;
`;

const Icon = styled.div`
    font-size: 48px;
    color: ${colors.neutral[300]};
    margin-bottom: 16px;
`;

const Title = styled.h3`
    font-size: 16px;
    font-weight: 500;
    color: ${colors.neutral[800]};
    margin: 0 0 8px 0;
`;

const Description = styled.p`
    font-size: 14px;
    color: ${colors.neutral[500]};
    margin: 0;
    line-height: 1.5;
`;

const EmptyStatus = ({ icon, title, description }) => {
    return (
        <Container>
            {icon && <Icon>{icon}</Icon>}
            {title && <Title>{title}</Title>}
            {description && <Description>{description}</Description>}
        </Container>
    );
};

export default EmptyStatus;
