import { useCallback, useEffect, useMemo, useState } from "react";
import { post } from "../networking/RequestService";

function useGetRecentNotes(params = {}, autoFetch = true) {
    const [recentNotes, setRecentNotes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const memoizedParams = useMemo(() => params, [JSON.stringify(params)]);

    const fetchRecentNotes = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await post({ fn: "recentNotes", ...memoizedParams });
            setRecentNotes(response.data.note);
        } catch (error) {
            setError(error.response?.data?.text || "An unexpected error occurred");
        } finally {
            setLoading(false);
        }
    }, [memoizedParams]);

    useEffect(() => {
        if (autoFetch) {
            fetchRecentNotes();
        }
    }, [fetchRecentNotes, autoFetch]);

    return { recentNotes, loading, error, fetchRecentNotes };
}

export default useGetRecentNotes;
