import React from "react";
import { connect } from "react-redux";
import ButtonComponent from "./parts/ButtonComponent";
import TabsComponent from "./parts/TabsComponent";
import SelectInputComponent from "./parts/SelectInputComponent";
import ExtensionCardComponent from "./parts/ExtensionCardComponent";
//import ProfileCardComponent from "./parts/ProfileCardComponent";
import RangeInputComponent from "./parts/RangeInputComponent";
import DateInputComponent from "./parts/DateInputComponent";
import SwitchComponent from "./parts/SwitchComponent";
import ExtensionTabs from "./parts/ExtensionTabs";

function PlaygroundHome() {
    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col-12">
                    <h1>Playground</h1>
                    <ButtonComponent />
                    <TabsComponent />
                    <SelectInputComponent />
                    <ExtensionCardComponent />
                    {/* <ProfileCardComponent /> */}
                    <RangeInputComponent />
                    <DateInputComponent />
                    <SwitchComponent />
                    <ExtensionTabs />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PlaygroundHome);
