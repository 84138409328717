import { useState } from "react";
import { post } from "../networking/RequestService";
import { useSelector } from "react-redux";

const useGetUserExtensionActivities = () => {
    const [userActivities, setUserActivities] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { user } = useSelector((state) => state.auth);

    const fetchUserExtensionActivities = async (customParams = {}) => {
        setLoading(true);
        setError(null);
        try {
            const res = await post({
                fn: "userActivityList",
                userId: user.id,
                ...customParams,
            });
            setUserActivities(res.data.myActivities);
        } catch (err) {
            setError(err);
            setUserActivities(null);
        } finally {
            setLoading(false);
        }
    };

    return { userActivities, loading, error, refetch: fetchUserExtensionActivities };
};
export default useGetUserExtensionActivities;
