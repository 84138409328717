import styled from "styled-components";

// Heading Components
export const Heading1 = styled.span`
    font-size: 1.8125rem;
    font-weight: 500;
    line-height: 2.2625rem;
    letter-spacing: -0.58px;
`;

export const Heading2 = styled.span`
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.875rem;
    letter-spacing: -0.384px;
`;

export const Heading3 = styled.span`
    font-size: 1.21875rem;
    font-weight: 600;
    line-height: 1.7688rem;
    letter-spacing: -0.234px;
`;

export const Heading4 = styled.span`
    font-size: 1.0625rem;
    font-weight: 600;
    line-height: 1.5063rem;
    letter-spacing: -0.136px;
`;

export const Heading5 = styled.span`
    font-size: 0.91563rem;
    font-weight: 500;
    line-height: 1.2938rem;
`;

// Body Components
export const Body1 = styled.span`
    font-size: 0.91563rem;
    font-weight: 400;
    line-height: 1.2938rem;
`;

export const Heading6 = styled.span`
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.0638rem;
`;

export const Body2 = styled.span`
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.0638rem;
`;

export const ExtensionBody = styled.span`
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.17px;
    line-height: 21px;
`;

export const ExtensionSmall = styled.span`
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
`;
