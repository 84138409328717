import React from "react";
import styled from "styled-components";
import { colors } from "app/popleads/components/ui-kit/colors";
import ButtonWithText from "app/popleads/components/ui-kit/Button";
import { FormattedNumber } from "react-intl";
import { useExtensionContext } from "../ExtensionContext";

export const ExtensionNewDealFooter = styled.div`
    width: 100%;
    min-height: 32px;
    background-color: #fff;
    border-radius: 0 0 8px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border-top: 1px solid ${colors.neutral[100]};
`;

const Footer = ({ onSubmit }) => {
    const { dealObject } = useExtensionContext();

    return (
        <ExtensionNewDealFooter>
            <div>
                <div style={{ fontSize: "12px", color: colors.neutral[600] }}>Total Amount</div>
                <div style={{ fontSize: "14px", color: colors.success[900], fontWeight: "500" }}>
                    {dealObject.amount ? (
                        <FormattedNumber
                            minimumFractionDigits={0}
                            maximumFractionDigits={2}
                            value={dealObject.amount}
                        />
                    ) : (
                        "-"
                    )}
                </div>
            </div>

            <ButtonWithText variant="primary" size="small" buttonStyle="default" onClick={onSubmit}>
                Register Deal
            </ButtonWithText>
        </ExtensionNewDealFooter>
    );
};

export default Footer;
