import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "./colors";
import { Heading5, Heading6 } from "./Text";
import EmptyStatus from "../../modules/extension/components/common/EmptyStatus";

//todo: 0.875 rem  yerine 28. satırda başka bir font-size kullanıldı (content ve right content)
//todo: rightcontent'de #00856C olmadığından başka renk kullanıldı TEKİN: #00856A olan success.900 kullanılabilir.
//todo: 1 rem olmadığı için heading5 kullanıldı
const CardContainer = styled.div`
    background-color: ${({ variant }) => (variant === "borderless" || variant === "only-hr" ? "none" : "#fff")};
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    border: ${({ variant }) =>
        variant === "borderless" || variant === "only-hr" ? "none" : `1px solid ${colors.neutral[100]}`};
    box-shadow: ${({ variant }) =>
        variant === "borderless" || variant === "only-hr" ? "none" : "0px 0px 1px 0px rgba(0, 0, 0, 0.2)"};
    padding: 0px 8px;
    margin: 8px 0px;
`;

/*const StyledCardContent = styled(Heading6)`
    color: ${colors.neutral[800]};
    letter-spacing: -0.17px;
`;
const StyledFooter = styled(Body2)`
    color: ${colors.neutral[800]};
    margin-top: 4px;
`;*/
const StyledContentWrapper = styled.div`
    /* border-bottom: ${({ isLast, variant }) =>
        isLast || variant === "borderless" ? "none" : `1px solid ${colors.neutral[100]}`}; */
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${({ variant }) => (variant === "detailPage" ? "8px" : "4px")};
`;
/*const StyledTitle = styled(Body2)`
    color: ${colors.neutral[800]};
    margin-bottom: 4px;
`;*/
const StyledIconContainer = styled.div`
    min-width: ${({ iconWidth }) => iconWidth ?? "24px"};
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const StyledButtonContainer = styled.div`
    //margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const ViewMoreButton = styled(Heading6)`
    color: ${colors.primary[900]};
    cursor: pointer;
    //margin-top: 8px;
    display: block;
`;

/*const StyledRightContent = styled(Heading6)`
    color: ${colors.success[800]};
    letter-spacing: -0.17px;
`;*/

const StyledImageBox = styled("img")`
    width: 40px;
    height: 40px;
    border-radius: 4px;
`;
const StyledCardTitle = styled(Heading5)`
    color: ${colors.neutral[600]};
    display: block;
    width: 100%;
`;
const StyledCardItem = styled("div")`
    padding: ${({ variant }) => (variant === "only-hr" ? "16px 0px" : "8px 0px")};
    display: flex;
    align-items: center;
    gap: 12px;
    border-bottom: ${({ isLast, variant }) =>
        isLast || (variant !== "only-hr" && variant === "borderless") ? "none" : `1px solid ${colors.neutral[200]}`};
`;
const ExtensionCard = ({
    iconWidth,
    cardTitle,
    cardItems,
    button,
    viewMoreOnClick,
    key,
    haveViewMore = true,
    variant,
    component,
    errorDescription,
}) => {
    const [viewMore, setViewMore] = useState(false);
    const visibleItems = cardItems ? (!haveViewMore || viewMore ? cardItems : cardItems.slice(0, 3)) : [];
    return (
        <div key={key}>
            <ExtensionCardHeader cardTitle={cardTitle} button={button} />

            {component}
            {visibleItems.length > 0 ? (
                <CardContainer variant={variant}>
                    {visibleItems.map((cardItem, index) => (
                        <ExtensionCardItem
                            key={cardItem.id ? cardItem.id : index}
                            variant={variant}
                            iconWidth={iconWidth}
                            cardItem={cardItem}
                            index={index}
                            isLast={index === visibleItems.length - 1}
                        />
                    ))}
                </CardContainer>
            ) : (
                <EmptyStatus description={errorDescription} />
            )}

            {/* Eğer kart elemanları 3'ten fazla ise View More/View Less butonunu göster */}
            {cardItems?.length > 3 && haveViewMore && (
                <ViewMoreButton onClick={() => (viewMoreOnClick ? viewMoreOnClick() : setViewMore(!viewMore))}>
                    {viewMore ? "View Less" : "View More"}
                </ViewMoreButton>
            )}
        </div>
    );
};

export const ExtensionCardHeader = ({ cardTitle, button }) => {
    return (
        <StyledButtonContainer>
            <StyledCardTitle>{cardTitle}</StyledCardTitle>
            {button}
        </StyledButtonContainer>
    );
};

export const ExtensionCardItem = ({ variant, cardItem, iconWidth, index, isLast }) => {
    return (
        <StyledCardItem variant={variant} isLast={isLast} key={index}>
            {
                <StyledIconContainer iconWidth={iconWidth}>
                    {cardItem.icon
                        ? cardItem.icon
                        : cardItem.customImage
                        ? cardItem.customImage
                        : cardItem.imgSrc && <StyledImageBox alt={cardItem.imgSrc} src={cardItem.imgSrc} />}
                </StyledIconContainer>
            }
            <StyledContentWrapper variant={variant} isLast={isLast}>
                {cardItem.header && cardItem.header}
                {cardItem.content && cardItem.content}
                {cardItem.footer && cardItem.footer}

                {/*{cardItem.title && <StyledTitle>{cardItem.title}</StyledTitle>}
                <div className="d-flex justify-content-between align-items-center">
                    {cardItem.content && <StyledCardContent>{cardItem.content}</StyledCardContent>}
                    {cardItem.rightContent && <StyledRightContent>{cardItem.rightContent}</StyledRightContent>}
                </div>
                {cardItem.footer && <StyledFooter>{cardItem.footer}</StyledFooter>}*/}
            </StyledContentWrapper>
        </StyledCardItem>
    );
};

export default ExtensionCard;
