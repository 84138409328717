export const getDealName = (deal) => deal?.campaign?.ad || deal?.courseName || deal?.ad;

export const createDealList = (deals, status) => {
    return (
        deals
            ?.filter((deal) => {
                if (status === "IN PROGRESS") {
                    return deal.durum !== "Won" && deal.durum !== "Lost" && deal.durum !== "Cancelled";
                }
                return deal.durum === status;
            })
            .sort((a, b) => new Date(b.createdTime) - new Date(a.createdTime))
            .map((deal) => ({
                name: getDealName(deal),
                date: "",
                ...(status === "IN PROGRESS" && { durum: deal.durum }),
            })) || []
    );
};

export const createDealViewSideBar = (deals) => {
    return [
        { name: "WON", deals: createDealList(deals, "Won") },
        { name: "IN PROGRESS", deals: createDealList(deals, "IN PROGRESS") },
        { name: "LOST", deals: createDealList(deals, "Lost") },
        { name: "CANCELLED", deals: createDealList(deals, "Cancelled") },
    ];
};
