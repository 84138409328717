import { useState } from "react";
import { post } from "../networking/RequestService";
import { toast } from "react-toastify";
import { useExtensionContext } from "../modules/extension/ExtensionContext";

const useGorevEkle = (profileId) => {
    const [gorev, setGorev] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { refetchProfile } = useExtensionContext();

    const gorevEkle = async ({
        metin,
        zaman,
        profilId,
        guncelleProfilId,
        assignedToIds,
        activityId,
        type,
        important,
        customerHappiness,
        taskColour,
    }) => {
        setLoading(true);
        setError(null);
        try {
            const res = await post({
                fn: "gorevEkle",
                metin,
                zaman,
                profilId,
                guncelleProfilId,
                assignedToIds,
                activityId,
                type,
                important,
                customerHappiness,
                taskColour,
            })
                .then((res) => {
                    toast.success("Task created successfully");
                    refetchProfile();
                })
                .catch(() => toast.error("Task creation failed"));
            const profile = res.data.profile;
            profile.inhouseInquiries = profile.gorev
                .filter((item) => item.activityType === "In-house Inquiry")
                .map((item) => ({
                    label: item.activityName,
                    value: item.activityId,
                }));

            setGorev(profile);
        } catch (err) {
            setError(err);
            setGorev(null);
        } finally {
            setLoading(false);
        }
    };

    return { gorev, loading, error, gorevEkle };
};

export default useGorevEkle;
