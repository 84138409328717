import React from "react";
import ExtensionCard from "app/popleads/components/ui-kit/ExtensionCard";
import ButtonWithText from "app/popleads/components/ui-kit/Button";
import FeatherIcon from "feather-icons-react";
import moment from "moment";
import { POPUP_CONTENTS } from "../utils/constants";
import { useExtensionContext } from "../ExtensionContext";
import { StyledCardContent, StyledCardFooter } from "./common/styled";

const ExtensionCourseCard = () => {
    const { profile, setState } = useExtensionContext();

    const etiketList = profile?.etiket.map((etiket) => {
        return {
            icon: <FeatherIcon strokeWidth="1px" icon="book-open" size="16" />,
            content: <StyledCardContent>{etiket.metin}</StyledCardContent>,
            footer: <StyledCardFooter>TYPE | {moment(etiket.createdTime).format("MMMM YYYY")}</StyledCardFooter>,
        };
    });

    return (
        <ExtensionCard
            button={
                <ButtonWithText
                    icon="add"
                    iconType="material"
                    variant="primary"
                    buttonStyle="default"
                    size="circle"
                    onClick={() => {
                        setState({ popupContent: POPUP_CONTENTS.COURSES });
                    }}
                />
            }
            cardTitle={"Courses"}
            cardItems={etiketList}
            viewMoreOnClick={() => {
                setState({ popupContent: POPUP_CONTENTS.COURSES });
            }}
            errorDescription="Click the + to assign an course."
        />
    );
};

export default ExtensionCourseCard;
