import { useEffect, useState } from "react";
import { post } from "../networking/RequestService";

const useGetProfileNotes = (queryProfileId, start = 0, rowsPerPage = 100, refetch) => {
    const [profileNotes, setProfileNotes] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchProfileNotes = async (customParams = {}) => {
        if (!queryProfileId) return;

        setLoading(true);
        setError(null);

        try {
            const res = await post({
                fn: "profileNoteList",
                profileId: queryProfileId,
                start: start,
                rowsPerPage: rowsPerPage,
                ...customParams,
            });
            setProfileNotes(res.data.profileNotes);
        } catch (err) {
            setError(err);
            setProfileNotes(null);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProfileNotes();
    }, [queryProfileId, start, rowsPerPage, refetch]);

    return { profileNotes, loading, error, refetch: fetchProfileNotes };
};

export default useGetProfileNotes;
