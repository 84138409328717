import { useEffect, useState } from "react";
import { post } from "../networking/RequestService";

function useGetRecentPersonalActivity(autoFetch = true) {
    const [recentActivity, setRecentActivity] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchRecentPersonalActivity = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await post({ fn: "recentPersonalActivity" });
            setRecentActivity(response.data.activity);
        } catch (error) {
            setError(error.response?.data?.text || "An unexpected error occurred");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (autoFetch) {
            fetchRecentPersonalActivity();
        }
    }, [autoFetch]);

    return { recentActivity, loading, error, fetchRecentPersonalActivity };
}

export default useGetRecentPersonalActivity;
