import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { post } from "../networking/RequestService";
import { actions } from "../modules/activities/redux/activitiesRedux";

export const useCompanyData = () => {
    //const [_companyUsersAndGroupsData, _setCompanyUsersAndGroupsData] = useState([]);
    //const { companyUsersData, companyGroupsData, companyUsersAndGroupsData } = useSelector(state => state.activities);
    const dispatch = useDispatch();
    const [companyUsers, setCompanyUsers] = useState([]);
    const [companyGroups, setCompanyGroups] = useState([]);
    const [companyUsersAndGroups, setCompanyUsersAndGroups] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const companyUsersObj = {
                fn: "companyUsers",
            };

            const companyGroupsObj = {
                fn: "companyGroups",
            };

            try {
                const usersResponse = await post(companyUsersObj);
                const users = usersResponse.data.users;

                dispatch(actions.setCompanyUsersData(users));
                setCompanyUsers(users);

                const groupsResponse = await post(companyGroupsObj);
                const groups = groupsResponse.data.groups.map((group) => ({
                    ...group,
                    isGroup: true,
                }));

                dispatch(actions.setCompanyGroupsData(groups));
                setCompanyGroups(groups);

                dispatch(actions.setCompanyUsersAndGroupsData([...users, ...groups]));
                setCompanyUsersAndGroups([...users, ...groups]);
            } catch (error) {
                //console.error("Error fetching company data:", error);
            }
        };
        fetchData();
    }, []);

    return { companyUsers, companyGroups, companyUsersAndGroups };
};

export default useCompanyData;
