import React from "react";
import styled from "styled-components";
import { Body2, Heading4, Heading5 } from "app/popleads/components/ui-kit/Text";
import { colors } from "app/popleads/components/ui-kit/colors";
import Toggle from "app/popleads/components/ui-kit/Toggle";
import { toAbsoluteUrl } from "_metronic/_helpers";
import SVG from "react-inlinesvg";
import ExtensionCard from "app/popleads/components/ui-kit/ExtensionCard";
import FeatherIcon from "feather-icons-react";
import ButtonWithText from "app/popleads/components/ui-kit/Button";
import { useExtensionContext } from "../ExtensionContext";
import { getDealName } from "../utils/deal-utils";
import moment from "moment";
import Label from "app/popleads/components/ui-kit/Label";
import { nameTag } from "app/popleads/app/functions/util";

//todo : #00856A rengi yok onun yerine paid amounnta success kullandım
//todo: Activities Payment başlıklarında heading4 kullanıldı fakat düzeltilmeli
//todo: #00856C rengi olmadığı için right content farklı  TEKİN: #00856A olan success.900 kullanılabilir.

const activities = [
    { id: 1, activityName: "Invoice Requisiton", type: "invoice" },
    { id: 2, activityName: "Proposal", type: "proposal" },
    { id: 3, activityName: "Course Campaign", type: "campaign" },
];
const paidAmounts = [
    { date: "Nov 12, 2024 09:30", amount: "$1,000" },
    { date: "Nov 12, 2024 09:30", amount: "$1,000" },
    { date: "Nov 12, 2024 09:30", amount: "$1,000" },
    { date: "Nov 12, 2024 09:30", amount: "$1,000" },
];
const options = [
    { label: "Yes", value: "Yes", activeVariant: "success" },
    { label: "No", value: "No", activeVariant: "neutral" },
];
const StyledTitle = styled(Heading4)`
    margin-bottom: 18px;
    color: ${colors.neutral[700]};
    display: block;
`;
const StyledContainer = styled("div")``;
const StyledContentText = styled(Body2)`
    color: ${colors.primary[900]};
`;

export const StyledItemContainer = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`;
const StyledPaidContainer = styled(StyledItemContainer)`
    align-items: start;
`;
const StyledDisabledText = styled(StyledContentText)`
    color: ${colors.neutral[700]};
`;
const StyledHorizontalLine = styled("div")`
    border-bottom: 1px solid ${colors.neutral[100]};
    margin-top: 12px;
    margin-bottom: 12px;
`;
const StyledAmountInfo = styled("div")`
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: ${({ isLast }) => (isLast ? "0" : "12px")};
`;

const StyledPaidDate = styled(StyledContentText)`
    color: ${colors.neutral[800]};
`;
const StyledAmount = styled(StyledContentText)`
    color: ${colors.success[800]};
    font-weight: 500;
`;
const ButtonContiner = styled("div")`
    display: flex;
    align-items: center;
    gap: 20px;
`;

const StyledNameTag = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background-color: ${colors.primary[50]};
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.primary[700]};
    font-weight: 500;
    font-size: 16px;
`;

const StyledDealTitle = styled(Heading5)`
    color: ${colors.neutral[800]};
    margin-bottom: 4px;
`;

const StyledDealContent = styled(Body2)`
    color: ${colors.neutral[800]};
`;

const StyledRightContent = styled(Body2)`
    color: ${colors.success[900]};
    font-weight: 500;
`;

const StyledFooterText = styled(Body2)`
    color: ${colors.neutral[600]};
`;

const StyledContentWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const StyledLeftContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const ExtensionViewDeal = ({ isActive, onEditClick }) => {
    const { profile } = useExtensionContext();
    const deal = profile?.olanak?.find((deal) => {
        if (!deal) return false;
        const dealName = getDealName(deal);
        return dealName === isActive;
    });

    const availableActivities = activities.filter((activity) => {
        switch (activity.type) {
            case "invoice":
                return !!deal?.invoiceRequisitionActivityId;
            case "proposal":
                return !!deal?.activityId;
            case "campaign":
                return !!deal?.inHouseId;
            default:
                return false;
        }
    });

    const selfInvestment = deal?.isSelfInvestment === 1 ? "Yes" : "No";
    const vatIncluded = deal?.vatIncludes === 1 ? "Yes" : "No";

    const cardItems = [
        {
            content: (
                <StyledContentWrapper>
                    <StyledLeftContent>
                        <StyledDealTitle>{profile?.accountName}</StyledDealTitle>
                    </StyledLeftContent>
                    <StyledRightContent>
                        <Label variant="success">
                            {profile?.dealsWonCount + profile?.dealsWonCount === 1 ? " Deal" : " Deals"}
                        </Label>
                    </StyledRightContent>
                </StyledContentWrapper>
            ),
            imgSrc: profile?.accountLogoUrl
                ? profile?.accountLogoUrl
                : "https://c.popleads.com/l3rn-online/course/Audit-Regulation-and-Compliance.jpg",
        },
        deal?.campaign && {
            content: <StyledDealTitle>{deal?.campaign?.ad}</StyledDealTitle>,
            imgSrc: deal?.campaign?.imgURL || "",
            customImage: !deal?.campaign?.imgURL ? <StyledNameTag>{nameTag(deal?.campaign?.ad)}</StyledNameTag> : null,
        },
        deal?.courseName && {
            icon: (
                <div>
                    <FeatherIcon strokeWidth="1px" icon="thumbs-up" size="16" />
                </div>
            ),
            content: (
                <StyledContentWrapper>
                    <StyledLeftContent>
                        <StyledDealTitle>{deal?.courseName}</StyledDealTitle>
                        <StyledDealContent>{`${deal?.durum} | ${moment(deal?.createdTime).format(
                            "MMM DD, YYYY"
                        )}`}</StyledDealContent>
                    </StyledLeftContent>
                </StyledContentWrapper>
            ),
        },
        {
            icon: <SVG src={toAbsoluteUrl("/media/svg/popleads/money_bag.svg")} />,
            content: (
                <StyledContentWrapper>
                    <StyledLeftContent>
                        <StyledDealTitle>{deal?.type}</StyledDealTitle>
                        {deal?.kapanmaTarihi && (
                            <StyledDealContent>
                                Close Date: {moment(deal?.kapanmaTarihi).format("MMM DD, YYYY")}
                            </StyledDealContent>
                        )}
                        {deal?.dealTypeChannel && (
                            <StyledFooterText>Marketing Channel: {deal?.dealTypeChannel}</StyledFooterText>
                        )}
                    </StyledLeftContent>
                    <StyledRightContent>{deal?.sharedAmount ? `$${deal.sharedAmount}` : ""}</StyledRightContent>
                </StyledContentWrapper>
            ),
        },
    ].filter(Boolean);

    return (
        <StyledContainer>
            <ExtensionCard iconWidth="40px" variant="only-hr" haveViewMore={false} cardItems={cardItems} />
            {availableActivities.length > 0 && (
                <>
                    <StyledTitle>Activities</StyledTitle>
                    {availableActivities.map((activity, index) => (
                        <StyledItemContainer key={index}>
                            <StyledContentText>{activity.activityName}</StyledContentText>
                            <div>
                                <ButtonWithText
                                    icon="external-link"
                                    iconType="feather"
                                    variant="primary"
                                    size="circle"
                                    buttonStyle="default"
                                    onClick={() => {
                                        const baseUrl = "/activities?activityId=";
                                        let redirectId;

                                        switch (activity.type) {
                                            case "invoice":
                                                redirectId = deal?.invoiceRequisitionActivityId;
                                                break;
                                            case "proposal":
                                                redirectId = deal?.activityId;
                                                break;
                                            case "campaign":
                                                redirectId = deal?.inHouseId;
                                                break;
                                            default:
                                                redirectId = "";
                                        }

                                        if (redirectId) {
                                            window.open(baseUrl + redirectId, "_blank");
                                        }
                                    }}
                                />
                            </div>
                        </StyledItemContainer>
                    ))}
                </>
            )}
            <StyledTitle>Payment</StyledTitle>
            <StyledItemContainer>
                <StyledDisabledText>VAT Included</StyledDisabledText>
                <Toggle selected={vatIncluded} options={options} disabled={true} />
            </StyledItemContainer>
            <StyledItemContainer>
                <StyledDisabledText>Selft Investment</StyledDisabledText>
                <Toggle selected={selfInvestment} options={options} disabled={true} />
            </StyledItemContainer>
            <StyledItemContainer>
                <StyledDisabledText>Paid</StyledDisabledText>
                <ButtonContiner>
                    {deal?.paid === 1 && (
                        <ButtonWithText
                            icon="check"
                            iconType="feather"
                            variant="success"
                            size="circle"
                            buttonStyle="default"
                        />
                    )}
                    {deal?.paid === 0 && (
                        <ButtonWithText
                            icon="x"
                            iconType="feather"
                            variant="danger"
                            size="circle"
                            buttonStyle="default"
                        />
                    )}
                </ButtonContiner>
            </StyledItemContainer>
            <StyledHorizontalLine />
            <StyledPaidContainer>
                <StyledDisabledText>Paid Amount</StyledDisabledText>
                <div>
                    {paidAmounts.map((paidAmount, index) => (
                        <StyledAmountInfo isLast={index + 1 === paidAmount.length} key={index}>
                            <StyledPaidDate>{paidAmount.date}</StyledPaidDate>
                            <StyledAmount>{paidAmount.amount}</StyledAmount>
                        </StyledAmountInfo>
                    ))}
                </div>
            </StyledPaidContainer>
            <StyledHorizontalLine />
            <StyledItemContainer>
                <StyledDisabledText>Total Paid</StyledDisabledText>
                <StyledAmount>1,000$</StyledAmount>
            </StyledItemContainer>
        </StyledContainer>
    );
};

export default ExtensionViewDeal;
