import React from "react";
import styled from "styled-components";
import { colors } from "./colors";

const Container = styled.div``;

const StepContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
`;

const Step = styled.div`
    position: relative;
    text-align: center;
    font-size: 14px;

    &::before {
        content: "";
        position: absolute;
        top: -20.5px;
        left: 50%;
        transform: translateX(-${(props) => props.percentage}%);
        width: 6px;
        height: 6px;
        background-color: ${(props) => (props.isPassed ? colors.primary[900] : colors.neutral[200])};
        border-radius: 50%;
    }
`;

const StyledRangeInput = styled.input`
    -webkit-appearance: none;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    outline: none;
    background: ${(props) => `linear-gradient(
    to right,
    ${colors.primary[900]} 0%,
    ${colors.primary[900]} ${props.percentage}%,
    ${colors.neutral[200]} ${props.percentage}%,
    ${colors.neutral[200]} 100%
  )`};

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 24px;
        height: 24px;
        background: ${colors.white};
        border-radius: 50%;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        z-index: 10;
        position: relative;
        box-shadow: 0 0 0 2px #001b261a;
        background-image: url("/media/svg/icons/General/slider-lines.svg");
        background-size: 30%;
        background-repeat: no-repeat;
        background-position: center;
    }

    &::-moz-range-thumb {
        width: 24px;
        height: 24px;
        background: ${colors.white};
        border-radius: 50%;
        cursor: pointer;
        border: none;
        transition: all 0.2s ease-in-out;
        z-index: 10;
        position: relative;
    }

    &::-ms-thumb {
        width: 24px;
        height: 24px;
        background: ${colors.white};
        border-radius: 50%;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        z-index: 10;
        position: relative;
    }
`;

const SelectedValue = styled.div`
    position: relative;
    top: 0;
    margin-left: ${(props) => props.percentage}%;
    width: 25px;
    text-align: center;
    transform: translateX(-${(props) => props.percentage}%);
    color: ${colors.primary[900]};
    font-size: 12px;
`;

const RangeInput = ({ selected, setSelected, options, ...props }) => {
    const calculatePercentage = (value) => {
        return ((value - options.min) / (options.max - options.min)) * 100;
    };

    const percentage = calculatePercentage(selected);

    const steps = [];
    for (let i = options.min; i <= options.max; i += options.step) {
        steps.push(i);
    }

    return (
        <Container>
            <SelectedValue percentage={percentage}>{selected}%</SelectedValue>
            <StyledRangeInput
                type="range"
                value={selected}
                onChange={(e) => setSelected(Number(e.target.value))}
                min={options.min}
                max={options.max}
                step={options.step}
                percentage={percentage}
                {...props}
            />
            <StepContainer>
                {steps.map((stepValue) => (
                    <Step
                        key={stepValue}
                        percentage={calculatePercentage(stepValue)}
                        isPassed={stepValue <= selected}
                    />
                ))}
            </StepContainer>
        </Container>
    );
};

export default RangeInput;
