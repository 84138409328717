import React, { useEffect } from "react";
import ExtensionInputGroup from "./ExtensionInputGroup";
import { useExtensionContext } from "../ExtensionContext";
import { dealChannelTypesOptions, dealMarketingTypesOptions, INHOUSE_DEAL_OBJECT } from "../utils/constants";
import ButtonWithLabel from "app/popleads/components/ui-kit/ButtonWithLabel";
import ButtonWithText from "app/popleads/components/ui-kit/Button";

const ExtensionNewInhouseDeal = ({ values, setFieldValue, dealObject, setDealObject }) => {
    const { profile, companyUsers } = useExtensionContext();

    const formatAmount = (amount) => {
        return Number(amount) % 1 === 0 ? Math.floor(amount) : amount;
    };

    useEffect(() => {
        if (!dealObject.selectedInquiry) return;

        const inquiry = profile.olanak.find((item) => item.activityId === dealObject.selectedInquiry);

        if (inquiry) {
            const fieldUpdates = {
                selectedInquiry: inquiry,
                dealName: inquiry.ad,
                accountName: inquiry.accountName,
                amount: formatAmount(inquiry.amount),
                closingDate: inquiry.kapanmaTarihi,
                type: inquiry.type,
                channelType: inquiry.dealTypeChannel,
                partner: inquiry.partnerName,
                partnerUserId: companyUsers?.find((item) => item.name === inquiry.partnerName).id,
                vatIncluded: inquiry.vatIncludes === 1 ? "Yes" : "No",
                paidDeal: inquiry.paid === 1 ? "Yes" : "No",
            };

            // Update deal object state
            setDealObject((prev) => ({
                ...prev,
                ...fieldUpdates,
            }));
        }
    }, [dealObject.selectedInquiry, setFieldValue]);

    return (
        <>
            <ExtensionInputGroup
                label="Select Existing Inquiry"
                type="select"
                options={profile?.inhouseInquiries ?? []}
                disabled={dealObject.newInquiry}
                value={
                    dealObject.selectedInquiry
                        ? {
                              label: dealObject.selectedInquiry.activityName,
                              value: dealObject.selectedInquiry.activityId,
                          }
                        : ""
                }
                onChange={(selected) => {
                    setFieldValue("selectedInquiry", selected.value);
                    setDealObject({ ...dealObject, selectedInquiry: selected.value, newInquiry: false });
                }}
            />

            {!dealObject.newInquiry ? (
                <ButtonWithLabel
                    variant="primary"
                    buttonStyle="default"
                    size="circle"
                    icon={"add"}
                    iconType={"material"}
                    label={"Create New Inquiry"}
                    onClick={() =>
                        setDealObject({
                            ...dealObject,
                            selectedInquiry: null,
                            newInquiry: true,
                            ...INHOUSE_DEAL_OBJECT,
                        })
                    }
                />
            ) : (
                <div>
                    <ButtonWithText
                        variant="primary"
                        size="small"
                        buttonStyle="borderless"
                        onClick={() => setDealObject({ ...dealObject, newInquiry: false, ...INHOUSE_DEAL_OBJECT })}
                        style={{ padding: "0", height: "24px" }}
                    >
                        Or Select Existing Inquiry
                    </ButtonWithText>
                </div>
            )}

            {dealObject.selectedInquiry ? (
                <>
                    <ExtensionInputGroup
                        label="Deal Name"
                        type="input"
                        inpType="text"
                        value={dealObject.dealName}
                        onChange={(selected) => {
                            setFieldValue("dealName", selected);
                            setDealObject({ ...dealObject, dealName: selected });
                        }}
                    />

                    <ExtensionInputGroup
                        label="Account Name"
                        type="input"
                        inpType="text"
                        value={dealObject.accountName}
                        onChange={(selected) => {
                            setFieldValue("accountName", selected);
                            setDealObject({ ...dealObject, accountName: selected });
                        }}
                    />

                    <ExtensionInputGroup
                        label="Course Name"
                        type="select"
                        isCreatable={true}
                        options={
                            dealObject.selectedInquiry
                                ? [
                                      {
                                          label: dealObject.selectedInquiry.courseName,
                                          value: dealObject.selectedInquiry.courseName,
                                      },
                                  ]
                                : []
                        }
                        value={
                            dealObject.courseName ? { label: dealObject.courseName, value: dealObject.courseName } : ""
                        }
                        onChange={(selected) => {
                            setFieldValue("courseName", selected.value);
                            setDealObject({ ...dealObject, courseName: selected.value });
                        }}
                    />

                    <ExtensionInputGroup
                        label="Amount"
                        type="input"
                        inpType="number"
                        leftMaterialIcon={"attach_money"}
                        iconType="material"
                        value={dealObject.amount}
                        onChange={(selected) => {
                            setFieldValue("amount", selected);
                            setDealObject({ ...dealObject, amount: selected });
                        }}
                    />

                    {/*<InputWithIcon
                            placeholder="Search Leads and Contacts"
                            type={"text"}
                            leftIcon={"search"}
                            value={searchText}
                            onChange={handleSearch}
                            disabled={loading}
                        rightIcon={
                            searchText.length > 0 && loading ? (
                                    <Spinner animation="border" variant="primary" size="sm" />
                                ) : searchText.length > 0 && !loading ? (
                                    "x"
                                ) : null
                            }
                        rightIconClick={clearSearch}
                        onFocus={() => setIsOpen(filteredResults.length > 0)}
                        onBlur={() => setTimeout(() => setIsOpen(false), 200)}
                />*/}

                    <ExtensionInputGroup
                        label="Closing Date"
                        type="date"
                        value={dealObject.closingDate}
                        onChange={(selected) => {
                            setFieldValue("closingDate", selected);
                            setDealObject({ ...dealObject, closingDate: selected });
                        }}
                    />

                    <ExtensionInputGroup
                        label="Type"
                        type="select"
                        options={dealMarketingTypesOptions}
                        value={dealObject.type ? { label: dealObject.type, value: dealObject.type } : ""}
                        onChange={(selected) => {
                            setFieldValue("type", selected);
                            setDealObject({ ...dealObject, type: selected.value });
                        }}
                    />

                    {(dealObject.type === "Marketing" || dealObject.type === "Marketing Cross Sale") && (
                        <ExtensionInputGroup
                            label="Channel Type"
                            type="select"
                            options={dealChannelTypesOptions}
                            value={
                                dealObject.channelType
                                    ? { label: dealObject.channelType, value: dealObject.channelType }
                                    : ""
                            }
                            onChange={(selected) => {
                                setFieldValue("channelType", selected);
                                setDealObject({ ...dealObject, channelType: selected.value });
                            }}
                        />
                    )}

                    <ExtensionInputGroup
                        label="Partner"
                        type="select"
                        options={companyUsers.map((item) => ({ label: item.name, value: item.id }))}
                        value={
                            dealObject.partner
                                ? {
                                      value: companyUsers?.find((item) => item.name === dealObject.partner).id,
                                      label: dealObject.partner,
                                  }
                                : ""
                        }
                        onChange={(selected) => {
                            setFieldValue("partner", selected);
                            setDealObject({ ...dealObject, partner: selected.label, partnerUserId: selected.value });
                        }}
                    />

                    <ExtensionInputGroup
                        label="VAT Included"
                        type="toggle"
                        size="toggle"
                        options={[
                            { value: "Yes", label: "Yes" },
                            { value: "No", label: "No" },
                        ]}
                        value={dealObject.vatIncluded}
                        onChange={(selected) => {
                            setFieldValue("vatIncluded", selected);
                            setDealObject({ ...dealObject, vatIncluded: selected });
                        }}
                    />

                    <ExtensionInputGroup
                        label="Paid Deal"
                        type="toggle"
                        size="toggle"
                        options={[
                            { value: "Yes", label: "Yes" },
                            { value: "No", label: "No" },
                        ]}
                        value={dealObject.paidDeal}
                        onChange={(selected) => {
                            setFieldValue("paidDeal", selected);
                            setDealObject({ ...dealObject, paidDeal: selected });
                        }}
                    />
                </>
            ) : dealObject.newInquiry ? (
                <>
                    <ExtensionInputGroup
                        label="Amount"
                        type="input"
                        inpType="number"
                        leftMaterialIcon={"attach_money"}
                        iconType="material"
                        value={dealObject.amount}
                        onChange={(selected) => {
                            setFieldValue("amount", selected);
                            setDealObject({ ...dealObject, amount: selected });
                        }}
                    />
                    <ExtensionInputGroup
                        label="Partner"
                        type="select"
                        options={companyUsers.map((item) => ({ label: item.name, value: item.id }))}
                        value={
                            dealObject.partner
                                ? {
                                      value: companyUsers?.find((item) => item.name === dealObject.partner).id,
                                      label: dealObject.partner,
                                  }
                                : ""
                        }
                        onChange={(selected) => {
                            setFieldValue("partner", selected);
                            setDealObject({ ...dealObject, partner: selected.label, partnerUserId: selected.value });
                        }}
                    />

                    {dealObject.status === "WON" && (
                        <>
                            <ExtensionInputGroup
                                label="Closing Date"
                                type="date"
                                value={dealObject.closingDate}
                                onChange={(selected) => {
                                    setFieldValue("closingDate", selected);
                                    setDealObject({ ...dealObject, closingDate: selected });
                                }}
                            />

                            <ExtensionInputGroup
                                label="Type"
                                type="select"
                                options={dealMarketingTypesOptions}
                                value={dealObject.type ? { label: dealObject.type, value: dealObject.type } : ""}
                                onChange={(selected) => {
                                    setFieldValue("type", selected);
                                    setDealObject({ ...dealObject, type: selected.value });
                                }}
                            />

                            {(dealObject.type === "Marketing" || dealObject.type === "Marketing Cross Sale") && (
                                <ExtensionInputGroup
                                    label="Channel Type"
                                    type="select"
                                    options={dealChannelTypesOptions}
                                    value={
                                        dealObject.channelType
                                            ? { label: dealObject.channelType, value: dealObject.channelType }
                                            : ""
                                    }
                                    onChange={(selected) => {
                                        setFieldValue("channelType", selected);
                                        setDealObject({ ...dealObject, channelType: selected.value });
                                    }}
                                />
                            )}
                        </>
                    )}
                </>
            ) : (
                <div>
                    <p>Please select an inquiry to create a new deal.</p>
                </div>
            )}
        </>
    );
};

export default ExtensionNewInhouseDeal;
