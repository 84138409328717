import React from "react";
import styled from "styled-components";

const ProgressContainer = styled.div`
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const ProgressSVG = styled.svg`
    position: absolute;
    transform: rotate(-90deg);
`;

const ProgressCircle = styled.circle`
    fill: none;
    stroke: ${(props) => props.color || "#0085BE"};
    stroke-width: 2;
    stroke-linecap: round;
    transition: all 0.3s ease;
`;

const ProgressBackground = styled.circle`
    fill: none;
    stroke: #e8ebed;
    stroke-width: 2;
`;

const IconContainer = styled.div`
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CircularProgress = ({ progress, size = 32, strokeWidth = 2, color, children, onClick }) => {
    const center = size / 2;
    const radius = (size - strokeWidth) / 2;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - (progress / 100) * circumference;

    return (
        <ProgressContainer style={{ width: size, height: size }} onClick={onClick}>
            <ProgressSVG width={size} height={size}>
                <ProgressBackground cx={center} cy={center} r={radius} />
                <ProgressCircle
                    cx={center}
                    cy={center}
                    r={radius}
                    strokeDasharray={circumference}
                    strokeDashoffset={offset}
                    color={color}
                />
            </ProgressSVG>
            <IconContainer>{children}</IconContainer>
        </ProgressContainer>
    );
};

export default CircularProgress;
