import { colors } from "app/popleads/components/ui-kit/colors";
import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { Spinner } from "react-bootstrap";
import ProfileCard from "app/popleads/components/ui-kit/ProfileCard";
import { toAbsoluteUrl } from "_metronic/_helpers";
import SVG from "react-inlinesvg";
import FeatherIcon from "feather-icons-react";
import ExtensionCard from "app/popleads/components/ui-kit/ExtensionCard";
import ButtonWithText from "app/popleads/components/ui-kit/Button";
import { useExtensionContext } from "../ExtensionContext";
import InputWithIcon from "app/popleads/components/ui-kit/Input";
import { StyledFooterButton } from "./ExtensionNewTask";
import Switch from "app/popleads/components/ui-kit/Switch";
import useDeleteProfileInfo from "app/popleads/hooks/useDeleteProfileInfo";
import Modal from "app/popleads/components/ui-kit/Modal";

const ExtensionPopupContentBody = styled.div`
    width: 100%;
    height: 100%;
    padding: 24px;
    overflow-y: auto;
`;

const StyledLocationContainer = styled("div")`
    display: flex;
    align-items: center;
    gap: 10px;
`;
const StyledButtonContainer = styled("div")`
    display: flex;
    align-items: center;
    gap: 12px;
`;
const StyledText = styled("div")`
    font-size: 14px;
    font-weight: 500;
    color: ${colors.neutral[800]};
`;

const StyledFooter = styled(StyledFooterButton)`
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 410px;
`;
// const StyledContainer = styled("div")`
//     display: flex;
//     flex-direction: column;
//     gap: 24px;
// `;
const StyledAboutPageWrapper = styled("div")`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 400px;
`;

const FIELD_TYPES = {
    EMAIL: "email",
    PHONE: "tel",
};

const FIELD_NAMES = {
    ad: "Name",
    site: "Linkedin URL",
    resim: "Image",
    baslik: "Title",
    sirket: "Company",
    country: "City/Country",
};

const MAX_ENTRIES = 2;

const ExtensionAboutPage = () => {
    const {
        profile,
        isProfileLoading,
        updateProfileLoading,
        handleUpdateProfile,
        refetchProfile,
        selectedModal,
        modals,
        setState,
        urlParams,
        fieldToUpdate,
    } = useExtensionContext();
    const { deleteProfileInfo, loading: deleteLoading } = useDeleteProfileInfo();
    const [emails, setEmails] = useState([]);
    const [phones, setPhones] = useState([]);
    const [newItemField, setNewItemField] = useState("");
    const [newItemValue, setNewItemValue] = useState("");
    const [itemToDelete, setItemToDelete] = useState(null);
    const [changedFields, setChangedFields] = useState({});

    useEffect(() => {
        if (profile && urlParams) {
            const changes = getChangedFields();
            setChangedFields(changes);
        }
    }, [profile, urlParams, getChangedFields]);

    const handleFieldUpdate = async (field, value) => {
        setState({
            selectedModal: modals.UPDATE_PROFILE,
            fieldToUpdate: { field, value },
        });
    };

    const handleConfirmUpdate = async () => {
        if (!fieldToUpdate) return;

        const result = await handleUpdateProfile({
            field: fieldToUpdate.field,
            fieldValue: fieldToUpdate.value,
            id: profile.id,
        });

        if (!result?.error) {
            const newChangedFields = { ...changedFields };
            delete newChangedFields[fieldToUpdate.field];
            setChangedFields(newChangedFields);
        }

        setState({ selectedModal: null, fieldToUpdate: null });
    };

    const getChangedFields = useCallback(() => {
        if (!profile || !urlParams) return {};

        const changes = {};
        if (urlParams.profil_baslik && profile.baslik !== urlParams.profil_baslik) {
            changes.baslik = urlParams.profil_baslik;
        }
        if (urlParams.profil_sirket && profile.sirket !== urlParams.profil_sirket) {
            changes.sirket = urlParams.profil_sirket;
        }
        if (urlParams.profil_ulke && profile.country !== urlParams.profil_ulke) {
            changes.country = urlParams.profil_ulke;
        }
        return changes;
    }, [profile, urlParams]);

    const handleApplyChanges = (section) => {
        const changes = Object.entries(changedFields).filter(([field]) => {
            if (section === "work") {
                return ["baslik", "sirket"].includes(field);
            } else if (section === "location") {
                return ["country"].includes(field);
            }
            return false;
        });

        if (changes.length > 0) {
            const [field, value] = changes[0];
            handleFieldUpdate(field, value);
        }
    };

    const workListArray = [
        {
            icon: <SVG src={toAbsoluteUrl("/media/svg/popleads/work-bag.svg")} />,
            content: <StyledText>{profile?.accountName || "Popleads Solutions"}</StyledText>,
        },
        urlParams?.profil_sirket &&
            profile?.sirket !== urlParams.profil_sirket && {
                content: (
                    <StyledLocationContainer>
                        <div>
                            <SVG src={toAbsoluteUrl("/media/svg/popleads/warning2.svg")} />
                        </div>
                        <StyledText>Changed to "{urlParams.profil_sirket}"</StyledText>
                    </StyledLocationContainer>
                ),
            },
        {
            icon: <SVG src={toAbsoluteUrl("/media/svg/popleads/bag-extension.svg")} />,
            content: <StyledText>{profile?.baslik || "Co-Founder & CEO"}</StyledText>,
        },
        urlParams?.profil_baslik &&
            profile?.baslik !== urlParams.profil_baslik && {
                content: (
                    <StyledLocationContainer>
                        <div>
                            <SVG src={toAbsoluteUrl("/media/svg/popleads/warning2.svg")} />
                        </div>
                        <StyledText>Changed to "{urlParams.profil_baslik}"</StyledText>
                    </StyledLocationContainer>
                ),
            },
    ].filter(Boolean);

    const locationArray = [
        {
            icon: <SVG src={toAbsoluteUrl("/media/svg/popleads/location.svg")} />,
            content: (
                <StyledText>
                    {profile?.city ? `${profile?.city}, ${profile?.country}` : profile?.country || "North Macedonia"}
                </StyledText>
            ),
        },
        urlParams?.profil_ulke &&
            profile?.country !== urlParams.profil_ulke && {
                content: (
                    <StyledLocationContainer>
                        <div>
                            <SVG src={toAbsoluteUrl("/media/svg/popleads/warning2.svg")} />
                        </div>
                        <StyledText>Changed to "{urlParams.profil_ulke}"</StyledText>
                    </StyledLocationContainer>
                ),
            },
    ].filter(Boolean);

    const hasWorkChanges = changedFields.baslik || changedFields.sirket;
    const hasLocationChanges = changedFields.country;

    const handleRemove = useCallback(
        async (field, value) => {
            try {
                const result = await deleteProfileInfo({
                    profileId: profile.id,
                    param: field,
                    paramValue: value,
                });

                if (!result?.error) {
                    refetchProfile();
                    setState({ selectedModal: null });
                }
            } catch (error) {
                setState({ selectedModal: null });
            }
        },
        [deleteProfileInfo, profile?.id, refetchProfile, setState]
    );

    const handleDeleteClick = useCallback(
        (field, value) => {
            setItemToDelete({ field, value });
            setState({ selectedModal: modals.DELETE_CONTACT });
        },
        [setState, modals]
    );

    const handleAdd = useCallback((field) => {
        setNewItemField(field);
    }, []);

    const validateNewValue = useCallback((newValue, profile) => {
        const allValues = Object.values(profile).filter(
            (value) => value && typeof value === "string" && value.trim() !== "" && value !== "undefined"
        );

        if (allValues.includes(newValue.trim())) {
            alert("This value already exists!");
            return false;
        }
        return true;
    }, []);

    const getNextEmptyField = useCallback((existingFields, fieldType) => {
        if (existingFields.length === 0) {
            return fieldType;
        } else if (existingFields.length < MAX_ENTRIES) {
            return `${fieldType}2`;
        }
        alert("Maximum 2 entries allowed!");
        return null;
    }, []);

    const handleInputKeyPress = useCallback(
        async (e) => {
            if (e.key === "Enter" && newItemValue.trim()) {
                if (!validateNewValue(newItemValue, profile)) return;

                const existingFields = Object.entries(profile)
                    .filter(
                        ([key, value]) =>
                            key.includes(newItemField) && value && typeof value === "string" && value.trim() !== ""
                    )
                    .map(([key]) => key);

                const emptyField = getNextEmptyField(existingFields, newItemField);
                if (!emptyField) return;

                try {
                    await handleUpdateProfile({
                        field: emptyField,
                        fieldValue: newItemValue.trim(),
                        id: profile.id,
                    });

                    setNewItemField("");
                    setNewItemValue("");
                    refetchProfile();
                } catch (error) {
                    //add error toast
                }
            }
        },
        [newItemValue, newItemField, profile, handleUpdateProfile, refetchProfile, getNextEmptyField, validateNewValue]
    );

    const handleCancelAdd = useCallback(() => {
        setNewItemField("");
        setNewItemValue("");
    }, []);

    const generateContent = useCallback(
        (profile, field, hasRightContent = false) => {
            if (!profile || !field) return [];

            const icon = field === FIELD_TYPES.EMAIL ? "mail" : "phone";
            const regex = new RegExp(`^${field}\\d?$`);
            let arrayOfContent = [];

            const items = Object.entries(profile).filter(
                ([key, value]) =>
                    key.includes(field) &&
                    regex.test(key) &&
                    value &&
                    typeof value === "string" &&
                    value.trim() !== "" &&
                    value !== "undefined"
            );

            if (items.length === 0 && newItemField !== field) {
                arrayOfContent.push({
                    icon: <FeatherIcon strokeWidth="1px" icon={icon} size="16" />,
                    content: (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                            }}
                        >
                            <StyledText>
                                <InputWithIcon
                                    variant="unstyled"
                                    value=""
                                    onChange={() => {}}
                                    placeholder={field === FIELD_TYPES.EMAIL ? "Add Email" : "Add Phone"}
                                    onClick={() => handleAdd(field)}
                                    style={{ cursor: "pointer" }}
                                />
                            </StyledText>
                            <StyledButtonContainer>
                                <ButtonWithText
                                    icon="add"
                                    iconType="material"
                                    variant="primary"
                                    size="circle"
                                    buttonStyle="default"
                                    onClick={() => handleAdd(field)}
                                />
                            </StyledButtonContainer>
                        </div>
                    ),
                });
                return arrayOfContent;
            }

            items.forEach(([key, value], index) => {
                arrayOfContent.push({
                    icon: <FeatherIcon strokeWidth="1px" icon={icon} size="16" />,
                    content: (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                            }}
                        >
                            <StyledText style={{ width: "100%" }}>
                                <InputWithIcon variant="unstyled" value={value} onChange={() => {}} />
                            </StyledText>
                            {hasRightContent && (
                                <StyledButtonContainer>
                                    <ButtonWithText
                                        icon="remove"
                                        iconType="material"
                                        variant="danger"
                                        size="circle"
                                        buttonStyle="inverted"
                                        onClick={() => handleDeleteClick(key, value)}
                                    />
                                    {index === 0 && items.length < MAX_ENTRIES && (
                                        <ButtonWithText
                                            icon="add"
                                            iconType="material"
                                            variant="primary"
                                            size="circle"
                                            buttonStyle="default"
                                            onClick={() => handleAdd(field)}
                                        />
                                    )}
                                </StyledButtonContainer>
                            )}
                        </div>
                    ),
                });
            });

            if (newItemField === field) {
                arrayOfContent.push({
                    icon: <FeatherIcon strokeWidth="1px" icon={icon} size="16" />,
                    content: (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                            }}
                        >
                            <StyledText>
                                <InputWithIcon
                                    variant="unstyled"
                                    value={newItemValue}
                                    onChange={(e) => setNewItemValue(e.target.value)}
                                    onKeyPress={handleInputKeyPress}
                                    autoFocus
                                    placeholder={field === FIELD_TYPES.EMAIL ? "Enter email" : "Enter phone"}
                                />
                            </StyledText>
                            <StyledButtonContainer>
                                <ButtonWithText
                                    icon="remove"
                                    iconType="material"
                                    variant="danger"
                                    size="circle"
                                    buttonStyle="inverted"
                                    onClick={handleCancelAdd}
                                />
                            </StyledButtonContainer>
                        </div>
                    ),
                });
            }

            return arrayOfContent;
        },
        [newItemField, newItemValue, handleAdd, handleDeleteClick, handleCancelAdd, handleInputKeyPress]
    );

    useEffect(() => {
        if (!profile) return;

        const validProfile = Object.fromEntries(
            Object.entries(profile).filter(
                ([_, value]) => value && typeof value === "string" && value.trim() !== "" && value !== "undefined"
            )
        );

        const emailContent = generateContent(validProfile, FIELD_TYPES.EMAIL, true);
        const phoneContent = generateContent(validProfile, FIELD_TYPES.PHONE, true);

        if (JSON.stringify(emails) !== JSON.stringify(emailContent)) {
            setEmails(emailContent);
        }
        if (JSON.stringify(phones) !== JSON.stringify(phoneContent)) {
            setPhones(phoneContent);
        }
    }, [profile, generateContent, emails, phones]);

    if (isProfileLoading || updateProfileLoading || deleteLoading) {
        return (
            <ExtensionPopupContentBody>
                <SpinnerContainer>
                    <Spinner animation="border" variant="primary" />
                </SpinnerContainer>
            </ExtensionPopupContentBody>
        );
    }

    return (
        <>
            <ExtensionPopupContentBody>
                <StyledAboutPageWrapper>
                    <ProfileCard variant="neutralInActive" />
                    <div>
                        <ExtensionCard cardTitle="Contact" cardItems={emails} />
                        <ExtensionCard cardItems={phones} />
                    </div>
                    <ExtensionCard
                        cardTitle="Location"
                        button={
                            hasLocationChanges && (
                                <ButtonWithText
                                    variant="primary"
                                    size="unstyled"
                                    buttonStyle="borderless"
                                    onClick={() => handleApplyChanges("location")}
                                >
                                    Apply Changes
                                </ButtonWithText>
                            )
                        }
                        cardItems={locationArray}
                    />
                    <ExtensionCard
                        haveViewMore={false}
                        cardTitle="Work"
                        button={
                            hasWorkChanges && (
                                <ButtonWithText
                                    variant="primary"
                                    size="unstyled"
                                    buttonStyle="borderless"
                                    onClick={() => handleApplyChanges("work")}
                                >
                                    Apply Changes
                                </ButtonWithText>
                            )
                        }
                        cardItems={workListArray}
                    />
                </StyledAboutPageWrapper>
            </ExtensionPopupContentBody>
            <StyledFooter>
                <ButtonWithText icon="remove" iconType="material" variant="danger" size="circle" type="inverted">
                    No-Export
                </ButtonWithText>
                <Switch />
            </StyledFooter>
            <Modal
                isOpen={selectedModal === modals.DELETE_CONTACT}
                onClose={() => setState({ selectedModal: null })}
                title="Are you sure you want to delete this contact?"
                variant="danger"
                icon="delete"
                onSuccess={() => itemToDelete && handleRemove(itemToDelete.field, itemToDelete.value)}
            >
                <div>This action cannot be undone.</div>
            </Modal>
            <Modal
                isOpen={selectedModal === modals.UPDATE_PROFILE}
                onClose={() => {
                    setState({ selectedModal: null });
                }}
                title="Update Profile Field"
                variant="primary"
                icon="edit"
                onSuccess={handleConfirmUpdate}
            >
                <div>
                    Do you want to update the {FIELD_NAMES[fieldToUpdate?.field] || fieldToUpdate?.field} to "
                    {fieldToUpdate?.value}"?
                </div>
            </Modal>
        </>
    );
};

export default ExtensionAboutPage;
