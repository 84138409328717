import React from "react";
import ButtonWithText from "./Button";

const Options = ({ selected, setSelected, options, ...props }) => {
    return (
        <div className="d-flex" style={{ gap: "8px" }}>
            {options.map((option, index) => (
                <ButtonWithText
                    variant={option.value === selected ? option.activeVariant : "passive"}
                    leftIcon={option.value === selected ? "check" : undefined}
                    buttonStyle={option.value === selected ? "selectedOption" : "option"}
                    size="option"
                    key={index}
                    onClick={(e) => {
                        e.preventDefault();
                        setSelected(option.value);
                    }}
                    {...props}
                >
                    {option.label}
                </ButtonWithText>
            ))}
        </div>
    );
};

export default Options;
