import React, { useState } from "react";
import ExtensionCard from "app/popleads/components/ui-kit/ExtensionCard";
import ButtonWithText from "app/popleads/components/ui-kit/Button";
import SVG from "react-inlinesvg";
import moment from "moment";
import { POPUP_CONTENTS } from "../utils/constants";
import { useExtensionContext } from "../ExtensionContext";
import { toAbsoluteUrl } from "_metronic/_helpers";
import { FormattedNumber } from "react-intl";
import * as util from "../../../app/functions/util";
import styled from "styled-components";
import { ExtensionBody, ExtensionSmall } from "app/popleads/components/ui-kit/Text";
import { colors } from "app/popleads/components/ui-kit/colors";
import ExtensionDealTabs from "../ExtensionDealTabs";

const StyledCardHeader = styled(ExtensionSmall)`
    color: ${colors.neutral[800]};
    display: flex;
`;

const StyledCardContent = styled(ExtensionBody)`
    color: ${colors.neutral[800]};
    display: flex;
`;
const StyledWonAmount = styled(ExtensionBody)`
    color: #00856c;
`;
const StyledCardFooter = styled(ExtensionSmall)`
    color: ${colors.neutral[500]};
    display: flex;
`;
const StyledFlexContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const ExtensionDealCard = () => {
    const { profile, setState } = useExtensionContext();
    const [selectedTabs, setSelectedTabs] = useState("Won");
    let inProgressDealAmount = 0;
    let wonDealAmount = 0;

    if (profile?.olanak) {
        for (let i = 0; i < profile.olanak.length; i++) {
            const olanak = profile.olanak[i];
            const durum = olanak.durum?.toLowerCase();
            const totalAmount = Number(olanak.totalAmount) || 0;

            if (durum !== "won" && durum !== "lost" && durum !== "cancelled") {
                inProgressDealAmount += totalAmount;
            } else if (durum === "won") {
                wonDealAmount += totalAmount;
            }
        }
    }
    const dealList = profile?.olanak
        .filter((olanak) => {
            const durum = olanak.durum?.toLowerCase();
            if (selectedTabs.toLowerCase() === "won") {
                return durum === "won";
            } else if (selectedTabs.toLowerCase() === "in progress") {
                return durum !== "won" && durum !== "lost" && durum !== "cancelled";
            } else {
                return durum === selectedTabs.toLowerCase();
            }
        })
        .sort((a, b) => (Number(b.totalAmount) || 0) - (Number(a.totalAmount) || 0))
        .map((olanak) => {
            return {
                icon: <SVG src={toAbsoluteUrl("/media/svg/popleads/money_bag.svg")} />,
                header: <StyledCardHeader>{moment(olanak.createdTime).format("MMM DD, YYYY")}</StyledCardHeader>,
                content: (
                    <StyledFlexContainer>
                        <StyledCardContent>
                            {olanak.campaign?.campaignTemplateCourseName ??
                                olanak.courseName ??
                                util.getDynamicValue(olanak.campaign?.columnsList, "Course Name") ??
                                olanak.campaign?.ad ??
                                "-"}
                        </StyledCardContent>
                        <StyledWonAmount>
                            $
                            <FormattedNumber
                                minimumFractionDigits={0}
                                maximumFractionDigits={2}
                                value={olanak.totalAmount}
                            />
                        </StyledWonAmount>
                    </StyledFlexContainer>
                ),
                footer: <StyledCardFooter>{olanak.ad}</StyledCardFooter>,
            };
        });

    return (
        <>
            <ExtensionCard
                cardTitle={"Deals"}
                component={
                    <ExtensionDealTabs
                        wonDealAmount={wonDealAmount}
                        inProgressDealAmount={inProgressDealAmount}
                        selectedTabs={selectedTabs}
                        setSelectedTabs={setSelectedTabs}
                    />
                }
                button={
                    <ButtonWithText
                        icon="add"
                        onClick={() => {
                            setState({ popupContent: POPUP_CONTENTS.NEW_DEAL });
                        }}
                        iconType="material"
                        variant="primary"
                        buttonStyle="default"
                        size="circle"
                    />
                }
                cardItems={dealList}
                viewMoreOnClick={() => {
                    setState({ popupContent: POPUP_CONTENTS.DEALS });
                }}
                errorDescription="Click the + to create a deal."
            />
        </>
    );
};

export default ExtensionDealCard;
