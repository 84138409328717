import React from "react";
import ExtensionInputGroup, { StyledLabel } from "./ExtensionInputGroup";
import styled from "styled-components";
import { dealChannelTypesOptions, dealMarketingTypesOptions } from "../utils/constants";
import { StyledItemContainer } from "./ExtensionViewDeal";
import Toggle from "app/popleads/components/ui-kit/Toggle";
import ExtensionCampaignSearchInput from "./ExtensionCampaignSearchInput";

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const options = [
    { label: "Yes", value: "Yes", activeVariant: "success" },
    { label: "No", value: "No", activeVariant: "neutral" },
];

const ExtensionNewPublicDeal = ({ dealObject, setDealObject, companyUsers, validateField }) => {
    const handleInputChange = (field, value) => {
        if (validateField(field, value)) {
            setDealObject({ ...dealObject, [field]: value });
        }
    };
    return (
        <StyledContainer>
            <ExtensionCampaignSearchInput
                optionClick={(value) => {
                    handleInputChange("campaign", value);
                }}
                value={dealObject.campaign}
                label="Campaign Name"
            />
            <ExtensionInputGroup
                label="Amount"
                type="input"
                inpType="number"
                min={0}
                leftIcon="dollar-sign"
                onChange={(e) => {
                    const value = e.target ? e.target.value : e;
                    handleInputChange("amount", value);
                }}
                value={dealObject.amount}
            />
            <ExtensionInputGroup
                label="Partner"
                type="select"
                loadOptions={(input, callback) =>
                    callback(
                        companyUsers
                            .filter((user) => user.name.toLowerCase().includes(input.toLowerCase()))
                            .map((item) => {
                                return {
                                    label: item.name,
                                    value: item.id,
                                };
                            })
                            .slice(0, 10)
                    )
                }
                onChange={(selected) => {
                    handleInputChange("partner", selected.value);
                }}
                defaultOptions
                value={
                    dealObject.partner
                        ? {
                              label: companyUsers.find((user) => user.id === dealObject.partner)?.name || "",
                              value: dealObject.partner,
                          }
                        : ""
                }
            />

            {dealObject.status === "WON" && (
                <>
                    <ExtensionInputGroup
                        label="Number of Units"
                        type="input"
                        inpType="number"
                        min={0}
                        onChange={(e) => {
                            const value = e.target ? e.target.value : e;
                            handleInputChange("numberOfUnits", value);
                        }}
                        value={dealObject.numberOfUnits}
                    />
                    <ExtensionInputGroup
                        label="Closing Date"
                        type="date"
                        onChange={(date) => {
                            handleInputChange("closingDate", date);
                        }}
                        value={dealObject.closingDate}
                    />
                    <ExtensionInputGroup
                        label="Select Type"
                        type="select"
                        options={dealMarketingTypesOptions}
                        value={dealObject.type ? { label: dealObject.type, value: dealObject.type } : ""}
                        onChange={(selected) => {
                            handleInputChange("type", selected.value);
                        }}
                    />
                    {(dealObject.type === "Marketing" || dealObject.type === "Marketing Cross Sale") && (
                        <ExtensionInputGroup
                            label="Select Marketing Channel"
                            type="select"
                            options={dealChannelTypesOptions}
                            value={
                                dealObject.channelType
                                    ? { label: dealObject.channelType, value: dealObject.channelType }
                                    : ""
                            }
                            onChange={(selected) => {
                                handleInputChange("channelType", selected.value);
                            }}
                        />
                    )}
                    <ExtensionInputGroup
                        label="Paid Amount"
                        type="input"
                        inpType="number"
                        leftIcon="dollar-sign"
                        onChange={(e) => {
                            const value = e.target ? e.target.value : e;
                            handleInputChange("paidAmount", value);
                        }}
                        value={dealObject.paidAmount}
                    />
                    <ExtensionInputGroup
                        label="Certificate Name"
                        type="input"
                        inpType="text"
                        onChange={(e) => {
                            const value = e.target ? e.target.value : e;
                            handleInputChange("certificateName", value);
                        }}
                        value={dealObject.certificateName}
                    />
                    <ExtensionInputGroup
                        label="Certificate Lastname"
                        type="input"
                        inpType="text"
                        onChange={(e) => {
                            const value = e.target ? e.target.value : e;
                            handleInputChange("certificateSurname", value);
                        }}
                        value={dealObject.certificateSurname}
                    />

                    <StyledItemContainer>
                        <StyledLabel>Self Investment</StyledLabel>
                        <Toggle
                            setSelected={(value) => {
                                handleInputChange("vatIncluded", value);
                            }}
                            selected={dealObject.vatIncluded}
                            options={options}
                        />
                    </StyledItemContainer>
                    <StyledItemContainer>
                        <StyledLabel>Self Investment</StyledLabel>
                        <Toggle
                            setSelected={(value) => {
                                handleInputChange("selfInvestment", value);
                            }}
                            selected={dealObject.selfInvestment}
                            options={options}
                        />
                    </StyledItemContainer>
                </>
            )}
            {(dealObject.status === "Lost" || dealObject.status === "Cancelled") && (
                <>
                    <ExtensionInputGroup
                        label="Number of Units"
                        type="input"
                        inpType="number"
                        onChange={(e) => {
                            const value = e.target ? e.target.value : e;
                            handleInputChange("numberOfUnits", value);
                        }}
                        value={dealObject.numberOfUnits}
                    />
                    <ExtensionInputGroup
                        label="Closing Date"
                        type="date"
                        onChange={(date) => {
                            handleInputChange("closingDate", date);
                        }}
                        value={dealObject.closingDate}
                    />
                    <ExtensionInputGroup
                        label="Select Type"
                        type="select"
                        options={dealMarketingTypesOptions}
                        value={dealObject.type ? { label: dealObject.type, value: dealObject.type } : ""}
                        onChange={(selected) => {
                            handleInputChange("type", selected.value);
                        }}
                    />
                    {(dealObject.type === "Marketing" || dealObject.type === "Marketing Cross Sale") && (
                        <ExtensionInputGroup
                            label="Select Marketing Channel"
                            type="select"
                            options={dealChannelTypesOptions}
                            value={
                                dealObject.channelType
                                    ? { label: dealObject.channelType, value: dealObject.channelType }
                                    : ""
                            }
                            onChange={(selected) => {
                                handleInputChange("channelType", selected.value);
                            }}
                        />
                    )}
                </>
            )}
        </StyledContainer>
    );
};

export default ExtensionNewPublicDeal;
