import React from "react";
import CircularProgress from "./CircularProgress";
import { toAbsoluteUrl } from "_metronic/_helpers";
import { decideFileType } from "app/popleads/modules/activities/utils";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import { colors } from "./colors";

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 278px;
    margin-bottom: 6px;
`;

const FileDetails = styled.div`
    display: flex;
    align-items: center;
    gap: 11px;
`;

const FileName = styled.div`
    font-size: 12px;
    font-weight: 500;
    color: ${colors.neutral[800]};
`;

const DownloadIcon = styled.span`
    font-size: 10px;
    color: ${colors.primary[900]};
    cursor: pointer;
`;

export default function NotesFileCard({ attachments }) {
    const handleDownload = (downloadLink, filename) => {
        if (!downloadLink) return;

        const anchorElement = document.createElement("a");
        anchorElement.href = downloadLink;
        anchorElement.download = filename || "download";
        anchorElement.target = "_blank";
        document.body.appendChild(anchorElement);
        anchorElement.click();
        document.body.removeChild(anchorElement);
    };

    if (!attachments || !Array.isArray(attachments) || attachments.length === 0) {
        return null;
    }

    return (
        <div className="d-flex flex-column mt-3">
            {attachments.map((attachment, index) =>
                attachment && attachment.filename ? (
                    <Container key={index}>
                        <FileDetails>
                            <SVG src={toAbsoluteUrl(decideFileType(attachment.filename))} />
                            <FileName>{attachment.filename}</FileName>
                        </FileDetails>
                        <CircularProgress
                            progress={100}
                            size={16}
                            color={"#0085BE"}
                            onClick={() => handleDownload(attachment.downloadLink, attachment.filename)}
                        >
                            <DownloadIcon className="material-symbols-outlined">arrow_downward</DownloadIcon>
                        </CircularProgress>
                    </Container>
                ) : null
            )}
        </div>
    );
}
