import React from "react";
import ExtensionCard from "app/popleads/components/ui-kit/ExtensionCard";
import { useExtensionContext } from "../ExtensionContext";
import styled from "styled-components";
import ExtensionNotesFooter from "./ExtensionNotesFooter";

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    overflow-y: auto;
    padding: 8px 12px;
`;
const Flex = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const ExtensionNotesPage = () => {
    const { noteList } = useExtensionContext();

    return (
        <Flex>
            <StyledContainer>
                <ExtensionCard cardItems={noteList} haveViewMore={false} variant="detailPage" />
            </StyledContainer>
            <ExtensionNotesFooter />
        </Flex>
    );
};

export default ExtensionNotesPage;
