import styled from "styled-components";
import { ExtensionBody, ExtensionSmall } from "app/popleads/components/ui-kit/Text";
import { colors } from "app/popleads/components/ui-kit/colors";

export const StyledCardHeader = styled(ExtensionSmall)`
    color: ${colors.neutral[800]};
    display: flex;
`;

export const StyledCardContent = styled(ExtensionBody)`
    color: ${({ variant }) => (variant ? colors[variant][800] : colors.neutral[800])};
    display: flex;
    font-weight: 400;
`;

export const StyledCardFooter = styled(ExtensionSmall)`
    color: ${colors.neutral[500]};
    display: flex;
`;

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 8px 12px;
`;

export const StyledTaskListHeader = styled(ExtensionBody)`
    color: ${colors.neutral[800]};
    display: flex;
    font-weight: 500;
    align-items: center;
    justify-content: space-between;
`;

export const ImportantTaskIcon = styled.div`
    color: ${colors.danger[900]};
    font-weight: 600;
    font-size: 16px;
    padding: 0px 4px;
`;

export const TaskColorIcon = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${({ color }) => color};
`;

export const StyledTaskListContent = styled(StyledCardContent)`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const StyledTaskListItemType = styled(ExtensionSmall)`
    color: ${colors.neutral[800]};
    display: flex;
`;

export const StyledTaskListItemContent = styled(ExtensionBody)`
    color: ${colors.neutral[500]};
    display: flex;
    font-weight: 400;
    border-bottom: 1px solid ${colors.neutral[100]};
    padding-bottom: 8px;
`;
