import { useState } from "react";
import { post } from "../networking/RequestService";

function useDeleteProfileInfo() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const deleteProfileInfo = async (params = {}) => {
        setLoading(true);
        setError(null);

        const { profileId, param, paramValue } = params;

        if (!profileId || !param || !paramValue) {
            const errorMessage = "Missing required parameters";
            setError(errorMessage);
            setLoading(false);
            return { error: errorMessage };
        }

        const payload = {
            fn: "deleteProfileInfo",
            profileId,
            param,
            paramValue,
        };

        try {
            const response = await post(payload);
            return { data: response.data };
        } catch (error) {
            const errorMessage = error?.response?.data?.text || error?.message || "An unexpected error occurred";
            setError(errorMessage);
            return { error: errorMessage };
        } finally {
            setLoading(false);
        }
    };

    return { deleteProfileInfo, loading, error };
}

export default useDeleteProfileInfo;
