import React, { useState } from "react";
import ButtonWithText from "./Button";

export default function NumberOfUnits() {
    const [number, setNumber] = useState(0);
    const handleIncrease = () => {
        setNumber(number - 1);
    };
    const handleDecrease = () => {
        setNumber(number + 1);
    };
    return (
        <div className="d-flex" style={{ gap: "8px" }}>
            <ButtonWithText
                variant="primary"
                size="circle"
                type="default"
                onClick={handleIncrease}
                icon={"remove"}
                iconType={"material"}
            />
            <div>{number}</div>
            <ButtonWithText
                variant="primary"
                size="circle"
                type="default"
                onClick={handleDecrease}
                icon={"add"}
                iconType={"material"}
            />
        </div>
    );
}
