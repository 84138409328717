import React from "react";
import ExtensionInputGroup from "./ExtensionInputGroup";
import { useExtensionContext } from "../ExtensionContext";
import styled from "styled-components";
import { colors } from "app/popleads/components/ui-kit/colors";
import { dealMarketingTypesOptions } from "../utils/constants";

export const StyledLabel = styled.label`
    display: block;
    font-size: 12px;
    color: ${colors.neutral[600]};
    margin-bottom: 0px;
`;

const ExtensionConsultingDeal = ({ dealObject, setDealObject, validateField }) => {
    const { companyUsers } = useExtensionContext();
    const StyledContainer = styled.div`
        display: flex;
        flex-direction: column;
        gap: 16px;
    `;

    const handleInputChange = (field, value) => {
        if (validateField(field, value)) {
            setDealObject({ ...dealObject, [field]: value });
        }
    };

    return (
        <StyledContainer>
            <ExtensionInputGroup
                label="Amount"
                type="input"
                inpType="number"
                leftIcon="dollar-sign"
                onChange={(e) => {
                    const value = e.target ? e.target.value : e;
                    handleInputChange("amount", value);
                }}
                value={dealObject.amount}
            />
            <ExtensionInputGroup
                label="Partner"
                type="select"
                loadOptions={(input, callback) =>
                    callback(
                        companyUsers
                            .filter((user) => user.name.toLowerCase().includes(input.toLowerCase()))
                            .map((item) => {
                                return {
                                    label: item.name,
                                    value: item.id,
                                };
                            })
                            .slice(0, 10)
                    )
                }
                onChange={(selected) => {
                    handleInputChange("partner", selected.value);
                }}
                defaultOptions
                value={
                    dealObject.partner
                        ? {
                              label: companyUsers.find((user) => user.id === dealObject.partner)?.name || "",
                              value: dealObject.partner,
                          }
                        : ""
                }
            />

            {dealObject.status === "WON" && (
                <>
                    <ExtensionInputGroup
                        label="Set Closing Date"
                        type="date"
                        onChange={(date) => {
                            handleInputChange("closingDate", date);
                        }}
                        value={dealObject.closingDate}
                    />
                    <ExtensionInputGroup
                        label="Deal Marketing Type"
                        type="select"
                        options={dealMarketingTypesOptions}
                        value={dealObject.type ? { label: dealObject.type, value: dealObject.type } : ""}
                        onChange={(selected) => {
                            handleInputChange("type", selected.value);
                        }}
                    />
                    <ExtensionInputGroup
                        label="Paid Amount"
                        type="input"
                        inpType="number"
                        leftIcon="dollar-sign"
                        onChange={(e) => {
                            const value = e.target ? e.target.value : e;
                            handleInputChange("paidAmount", value);
                        }}
                        value={dealObject.paidAmount}
                    />
                    <div className="d-flex align-items-center justify-content-between">
                        <StyledLabel>VAT Calculation</StyledLabel>
                        <ExtensionInputGroup
                            type="checkbox"
                            onChange={(e) => {
                                handleInputChange("vatIncluded", e.target.checked ? "Yes" : "No");
                            }}
                            value={dealObject.vatIncluded === "Yes"}
                        />
                    </div>
                </>
            )}

            {(dealObject.status === "Lost" || dealObject.status === "Cancelled") && (
                <>
                    <ExtensionInputGroup
                        label="Set Closing Date"
                        type="date"
                        onChange={(date) => {
                            handleInputChange("closingDate", date);
                        }}
                        value={dealObject.closingDate}
                    />
                    <ExtensionInputGroup
                        label="Select Amount"
                        type="input"
                        inpType="number"
                        leftIcon="dollar-sign"
                        onChange={(e) => {
                            const value = e.target ? e.target.value : e;
                            handleInputChange("amount", value);
                        }}
                        value={dealObject.amount}
                    />
                </>
            )}
        </StyledContainer>
    );
};

export default ExtensionConsultingDeal;
