import { useState } from "react";
import { post } from "../networking/RequestService";

const useTaskDelete = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const deleteTask = async (taskId) => {
        setLoading(true);
        setError(null);
        try {
            const res = await post({
                fn: "gorevSil",
                gorev: taskId,
                guncelleProfilId: "",
                multiple: false,
            });
            return res.data;
        } catch (err) {
            setError(err);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return { deleteTask, loading, error };
};

export default useTaskDelete;
