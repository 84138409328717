import { useState } from "react";
import { post } from "../networking/RequestService";
import moment from "moment";

const useTaskUpdate = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const updateTask = async (taskId, taskText, currentDate, profileId, daysToAdd) => {
        setLoading(true);
        setError(null);
        try {
            const newDate = moment(currentDate)
                .add(daysToAdd, "days")
                .valueOf();

            const res = await post({
                fn: "gorevGuncelle",
                gorevId: taskId,
                metin: taskText,
                zaman: newDate,
                guncelleProfilId: profileId,
                forceUpdate: 0,
            });
            return res.data;
        } catch (err) {
            setError(err);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return { updateTask, loading, error };
};

export default useTaskUpdate;
