import React, { useEffect, useRef, useState, forwardRef } from "react";
import styled from "styled-components";
import { colors } from "./colors";
import { Body2, Heading3, Heading6 } from "app/popleads/components/ui-kit/Text";
import InputWithIcon from "./Input";
import { useExtensionContext } from "app/popleads/modules/extension/ExtensionContext";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "_metronic/_helpers";

const Container = styled.div`
    display: flex;
`;

const ImageSection = styled.div`
    min-height: 88px;
    min-width: 88px;
    max-height: 88px;
    max-width: 88px;
    border-radius: 50%;
    padding: 1px;
    opacity: 1;
    background: transparent
        linear-gradient(
            90deg,
            ${colors.success[500]} 0%,
            #f6f8f914 37%,
            #f6f8f900 50%,
            #f6f8f914 63%,
            ${colors.success[500]} 100%
        )
        0% 0% no-repeat padding-box;
    text-align: center;
`;

const ImageBorder = styled.div`
    background-color: ${colors.neutral[50]};
    border-radius: 50%;
    padding: 4px;
    width: 100%;
    height: 100%;
`;

const Image = styled.img`
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    padding: 4px;
    border-radius: 50%;
    border: 1px solid ${colors.success[900]};
`;

const Star = styled.div`
    display: inline-block;
    height: 24px;
    width: 24px;
    background-color: #00856b;
    border-radius: 50%;
    color: #ffffff;
    position: relative;
    top: -23px;
    font-size: 18px;
    text-align: center;
    line-height: 24px;
`;

const ProfileInfo = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 12px;
    color: ${({ inActive }) => (inActive ? colors.neutral[500] : colors.neutral[800])};
    justify-content: center;
    flex: 1;
    overflow: hidden;
    gap: 5px;
`;

const Name = styled(Heading3)`
    line-height: 1.5rem;
    color: ${colors.neutral[900]};
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: ${(props) => (props.isMultiline ? "16px" : "18px")};
    max-height: 3rem;
    width: 100%;
    word-break: break-word;

    span {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }
`;

const Title = styled(Heading6)`
    display: flex;
    align-items: center;
    width: 100%;
    cursor: default;
    gap: 4px;
`;

const ProfileMenu = styled.div`
    padding: 0;
    display: flex;
    align-items: center;
`;

const NameWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
`;

const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    overflow: hidden;

    &.company {
        flex-shrink: 0;
        max-width: fit-content;
    }

    &.title {
        flex: 1;
        min-width: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const EditableWrapper = styled.span`
    display: flex;
    align-items: center;
    min-width: 0;

    &.title {
        max-width: 100%;

        > span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
        }
    }
`;

const MoreButton = styled.button`
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0;
    border: none;
    color: ${colors.neutral[700]};
    background-color: transparent;
`;

const ProfileCard = forwardRef(({ variant, ...props }, ref) => {
    const { profile, setState, urlParams } = useExtensionContext();
    const fallbackSrc = "https://dev.popleads.com/PopleadsDevCRM1/img/profilepic.png";
    const [imgSrc, setImgSrc] = useState(profile?.resim ? profile?.resim : fallbackSrc);
    const [isHidden, setIsHidden] = useState("");
    const [isMultiline, setIsMultiline] = useState(false);
    const nameRef = useRef(null);

    const checkMultiline = () => {
        if (nameRef.current) {
            const nameSpan = nameRef.current.querySelector("span");
            if (nameSpan) {
                const spanHeight = nameSpan.offsetHeight;
                const lineHeight = parseInt(getComputedStyle(nameSpan).lineHeight);
                setIsMultiline(spanHeight > lineHeight);
            }
        }
    };

    useEffect(() => {
        checkMultiline();
        window.addEventListener("resize", checkMultiline);
        return () => window.removeEventListener("resize", checkMultiline);
    }, [profile?.ad, profile?.soyad]);

    const getLinkedinUsername = (site) => {
        if (!site) return "";
        const regex = /\/in\/([a-zA-Z0-9\-]+)/;
        const match = site.match(regex);
        return match ? `/in/${match[1]}` : "";
    };

    const FIELD_NAMES = {
        ad: "Name",
        site: "Linkedin URL",
        resim: "Image",
        baslik: "Title",
        sirket: "Company",
        country: "City/Country",
    };

    const compareLinkedinUrls = (url1, url2) => {
        const username1 = getLinkedinUsername(url1);
        const username2 = getLinkedinUsername(url2);
        return username1 === username2;
    };

    const hasChanges =
        (urlParams?.profil_baslik !== undefined &&
            urlParams?.profil_baslik !== null &&
            urlParams?.profil_baslik !== profile?.baslik) ||
        (urlParams?.profil_sirket !== undefined &&
            urlParams?.profil_sirket !== null &&
            urlParams?.profil_sirket !== profile?.sirket) ||
        (urlParams?.profil_ulke !== undefined &&
            urlParams?.profil_ulke !== null &&
            urlParams?.profil_ulke !== profile?.country);

    const renderWarningIcon = (field, value, tooltip) => {
        if (value === undefined || value === null) return null;
        return (
            <OverlayTrigger placement="top" overlay={<Tooltip>{tooltip}</Tooltip>}>
                <div
                    style={{ cursor: "pointer", display: "inline-flex" }}
                    onClick={() =>
                        setState({
                            popupContent: "About",
                            selectedModal: "updateProfile",
                            fieldToUpdate: { field, value },
                        })
                    }
                >
                    <SVG src={toAbsoluteUrl("/media/svg/popleads/warning2.svg")} />
                </div>
            </OverlayTrigger>
        );
    };

    return (
        <Container {...props} ref={ref}>
            <ImageSection>
                <ImageBorder style={{ position: "relative" }}>
                    <Image src={imgSrc} alt="profile" onError={() => setImgSrc(fallbackSrc)} />
                    {variant === "neutralInActive" && urlParams?.profil_resim !== profile?.resim && (
                        <div style={{ position: "absolute", top: "4px", right: "4px" }}>
                            {renderWarningIcon("resim", urlParams?.profil_resim, `Changed to ${FIELD_NAMES.resim}`)}
                        </div>
                    )}
                </ImageBorder>
                {profile?.dealsWonCount > 0 && (
                    <OverlayTrigger
                        key="bottom"
                        placement="bottom"
                        overlay={
                            <Tooltip id={`tooltip-bottom`}>
                                <strong>{"Has " + profile?.dealsWonCount + " Won Deal"}</strong>
                            </Tooltip>
                        }
                    >
                        {<Star className="material-symbols-outlined">star</Star>}
                    </OverlayTrigger>
                )}
            </ImageSection>
            <ProfileInfo inActive={variant === "neutralInActive"}>
                <Name ref={nameRef} isMultiline={isMultiline}>
                    <NameWrapper>
                        <EditableWrapper>
                            <EditableField
                                value={
                                    profile?.ad && profile?.soyad
                                        ? `${profile?.ad} ${profile?.soyad}`
                                        : profile?.ad || profile?.soyad
                                }
                                field="ad"
                            />
                        </EditableWrapper>
                        {variant === "neutralInActive" &&
                            urlParams?.profil_ad !== profile?.ad &&
                            renderWarningIcon("ad", urlParams?.profil_ad, `Changed to ${FIELD_NAMES.ad}`)}
                    </NameWrapper>
                </Name>
                {variant === "neutralInActive" ? (
                    <Title
                        style={{ display: "flex", alignItems: "center", gap: "8px", justifyContent: "space-between" }}
                    >
                        <span>{profile?.site ? getLinkedinUsername(profile?.site) : "-"}</span>
                        {!compareLinkedinUrls(urlParams?.profil_url, profile?.site) &&
                            renderWarningIcon("site", urlParams?.profil_url, `Changed to ${FIELD_NAMES.site}`)}
                    </Title>
                ) : (
                    <>
                        <Title>
                            {isHidden !== "sirket" && (
                                <TitleWrapper className="company">
                                    <EditableWrapper onClick={() => setIsHidden("baslik")}>
                                        <EditableField
                                            value={profile?.sirket}
                                            field="sirket"
                                            setIsHidden={setIsHidden}
                                        />
                                    </EditableWrapper>
                                </TitleWrapper>
                            )}
                            {!isHidden && <span className="mx-1">|</span>}
                            {isHidden !== "baslik" && (
                                <TitleWrapper className="title">
                                    <EditableWrapper className="title" onClick={() => setIsHidden("sirket")}>
                                        <EditableField
                                            value={profile?.baslik}
                                            field="baslik"
                                            setIsHidden={setIsHidden}
                                        />
                                    </EditableWrapper>
                                </TitleWrapper>
                            )}
                        </Title>
                        <Body2>
                            <EditableField value={profile?.email} field="email" />
                        </Body2>
                        <Body2>
                            <EditableField value={profile?.tel} field="tel" />
                        </Body2>
                    </>
                )}
            </ProfileInfo>
            {variant !== "neutralInActive" ? (
                <div>
                    <ProfileMenu>
                        {hasChanges && (
                            <OverlayTrigger placement="top" overlay={<Tooltip>Profile has changes to apply</Tooltip>}>
                                <div style={{ cursor: "pointer" }} onClick={() => setState({ popupContent: "About" })}>
                                    <SVG src={toAbsoluteUrl("/media/svg/popleads/warning2.svg")} />
                                </div>
                            </OverlayTrigger>
                        )}
                        <MoreButton
                            onClick={() => {
                                setState({ popupContent: "About" });
                            }}
                        >
                            <span class="material-symbols-outlined">more_vert</span>
                        </MoreButton>
                    </ProfileMenu>
                </div>
            ) : null}
        </Container>
    );
});

export default ProfileCard;

function EditableField({ value, onChange, field, setIsHidden }) {
    const [isEditing, setIsEditing] = useState(false);
    const wrapperRef = useRef(null);
    const { handleUpdateProfile } = useExtensionContext();
    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isEditing && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                const newValue = inputValue?.trim();
                if (!newValue || newValue === value) {
                    setInputValue(value); // değer değişmediyse orijinal değere geri dön
                    setIsEditing(false);
                    if (setIsHidden) setIsHidden("");
                    return;
                }

                handleUpdateProfile({ field: field, fieldValue: newValue }); // Parent bileşene değişikliği bildir
                setIsEditing(false);
                if (setIsHidden) setIsHidden("");
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isEditing, inputValue, value, onChange, field, handleUpdateProfile]);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    useEffect(() => {
        if (isEditing) {
            const input = wrapperRef.current.querySelector("input");
            input.focus();
        }
    }, [isEditing]);

    return (
        <span ref={wrapperRef} style={{ cursor: "pointer" }}>
            {isEditing ? (
                <InputWithIcon
                    variant={"unstyled"}
                    debounceTime={0}
                    value={inputValue || ""}
                    onChange={(e) => setInputValue(e.target.value)}
                />
            ) : (
                <span onClick={() => setIsEditing(true)}>{value || "-"}</span>
            )}
        </span>
    );
}
