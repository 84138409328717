import { useState } from "react";
import { post } from "../networking/RequestService";

const useTaskComplete = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const completeTask = async (taskId) => {
        setLoading(true);
        setError(null);
        try {
            const res = await post({
                fn: "gorevTamamla",
                gorev: taskId,
                multiple: false,
            });
            return res.data;
        } catch (err) {
            setError(err);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return { completeTask, loading, error };
};

export default useTaskComplete;
