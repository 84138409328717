import CustomSelect from "app/popleads/components/ui-kit/Select";
import { post } from "app/popleads/networking/RequestService";
import React, { useState } from "react";
import { connect } from "react-redux";

function SelectInputComponent() {
    const [selectedSingleOption, setSelectedSingleOption] = useState();
    const [selectedMultiOption, setSelectedMultiOption] = useState();
    const [selectedSingleSearchOption, setSelectedSingleSearchOption] = useState();
    const [selectedMultiSearchOption, setSelectedMultiSearchOption] = useState();
    const [selectedCreatableOption, setSelectedCreatableOption] = useState();
    const [selectedCreatableAsyncOption, setSelectedCreatableAsyncOption] = useState();
    const [selectedCreatableMultiOption, setSelectedCreatableMultiOption] = useState();
    const [selectedCreatableAsyncMultiOption, setSelectedCreatableAsyncMultiOption] = useState();

    const options = [
        { label: "Adana", value: "Adana" },
        { label: "Istanbul", value: "Istanbul" },
        { label: "Konya", value: "Konya" },
        { label: "Ankara", value: "Ankara" },
        { label: "Trabzon", value: "Trabzon" },
        { label: "Kayseri", value: "Kayseri" },
        { label: "Gaziantep", value: "Gaziantep" },
    ];

    const fetchQuery = async (inputValue) => {
        const requestObj = {
            fn: "campaignListHomePage",
            search: inputValue,
        };

        try {
            const response = await post(requestObj);
            const data = response.data;
            const options = data.campaignList.map((item) => ({
                value: item.ad,
                label: item.ad,
            }));

            return {
                options,
                hasMore: false,
            };
        } catch (error) {
            // console.error("Error fetching data:", error);
        }
    };

    const loadOptions = (inputValue) =>
        new Promise((resolve) => {
            const options = fetchQuery(inputValue);
            resolve(options);
        });

    return (
        <div className="card card-custom mt-5">
            <div className="card-header">
                <h3 className="card-title">Select Input</h3>
            </div>
            <div className="card-body">
                <div className="d-flex">
                    <div className="col-6">
                        <h4>Single Selection</h4>
                        <CustomSelect
                            key="single-select"
                            options={options}
                            isClearable={true}
                            isSearchable={false}
                            value={selectedSingleOption}
                            onChange={(selection) => setSelectedSingleOption(selection)}
                            placeholder="Select Single..."
                        />
                    </div>
                    <div className="col-6">
                        <h4>Multi Selection</h4>
                        <CustomSelect
                            key="multi-select"
                            options={options}
                            isMulti={true}
                            isClearable={true}
                            isSearchable={false}
                            value={selectedMultiOption}
                            onChange={(selection) => setSelectedMultiOption(selection)}
                            placeholder="Select Multi..."
                        />
                    </div>
                </div>
                <div className="d-flex mt-5">
                    <div className="col-6">
                        <h4>Single Search Selection</h4>
                        <CustomSelect
                            key="single-select"
                            options={options}
                            value={selectedSingleSearchOption}
                            isClearable={true}
                            isSearchable={true}
                            onChange={(selection) => setSelectedSingleSearchOption(selection)}
                            placeholder="Single Search Selection..."
                        />
                    </div>
                    <div className="col-6">
                        <h4>Multi Search Selection</h4>
                        <CustomSelect
                            key="multi-select"
                            options={options}
                            isClearable={true}
                            isSearchable={true}
                            isMulti={true}
                            value={selectedMultiSearchOption}
                            onChange={(selection) => setSelectedMultiSearchOption(selection)}
                            placeholder="Multi Search Selection..."
                        />
                    </div>
                </div>
                <div className="d-flex mt-5">
                    <div className="col-6">
                        <h4>Creatable Selection</h4>
                        <CustomSelect
                            key="single-select"
                            options={options}
                            value={selectedCreatableOption}
                            isClearable={true}
                            isSearchable={true}
                            isCreatable={true}
                            onChange={(selection) => setSelectedCreatableOption(selection)}
                            placeholder="Creatable Selection..."
                        />
                    </div>
                    <div className="col-6">
                        <h4>Creatable Async Selection</h4>
                        <CustomSelect
                            key="single-select"
                            options={options}
                            value={selectedCreatableAsyncOption}
                            isClearable={true}
                            isSearchable={true}
                            isCreatable={true}
                            onChange={(selection) => setSelectedCreatableAsyncOption(selection)}
                            isMulti={false}
                            placeholder={"Creatable Async Selection..."}
                            loadOptions={loadOptions}
                        />
                    </div>
                </div>
                <div className="d-flex mt-5">
                    <div className="col-6">
                        <h4>Creatable Multi Selection</h4>
                        <CustomSelect
                            key="single-select"
                            options={options}
                            value={selectedCreatableMultiOption}
                            isClearable={true}
                            isSearchable={true}
                            isCreatable={true}
                            isMulti={true}
                            onChange={(selection) => setSelectedCreatableMultiOption(selection)}
                            placeholder="Creatable Multi Selection..."
                        />
                    </div>
                    <div className="col-6">
                        <h4>Creatable Async Multi Selection</h4>
                        <CustomSelect
                            key="single-select"
                            options={options}
                            value={selectedCreatableAsyncMultiOption}
                            isClearable={true}
                            isSearchable={true}
                            isCreatable={true}
                            isMulti={true}
                            onChange={(selection) => setSelectedCreatableAsyncMultiOption(selection)}
                            placeholder={"Creatable Async Multi Selection..."}
                            loadOptions={loadOptions}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SelectInputComponent);
