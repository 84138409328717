import React from "react";
import styled from "styled-components";
import { colors } from "./colors";

const TabsWrapper = styled.div`
    display: flex;
    padding: 2px;
    border-radius: 8px;
    background-color: ${colors.neutral[100]};
    width: 100%;
`;

const Button = styled.button`
    border: 0;
    font-size: 14px;
    border-radius: 6px;
    line-height: 14px;
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    outline: none;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 32px;
    color: ${({ isActive, disabled }) =>
        disabled ? colors.neutral[300] : isActive ? colors.neutral[700] : colors.neutral[500]};
    background-color: ${({ isActive, disabled }) => (disabled ? "transparent" : isActive ? "#FFFFFF" : "transparent")};
    box-shadow: ${({ isActive }) => (isActive ? "0px 2px 8px #001B261A;" : "none")};
    pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`;

const Tabs = ({ selected, setSelected, options, ...props }) => {
    return (
        <TabsWrapper>
            {options.map((option, index) => (
                <Button
                    key={index}
                    isActive={selected === option.value}
                    onClick={() => !option.disabled && setSelected(option.value)}
                    disabled={option.disabled}
                    {...props}
                >
                    {option.component ?? option.value}
                </Button>
            ))}
        </TabsWrapper>
    );
};

export default Tabs;
