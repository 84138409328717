import React, { useEffect } from "react";
import styled from "styled-components";
import ExtensionDealSideBar from "./ExtensionDealSideBar";
import ExtensionViewDeal from "./ExtensionViewDeal";
import ExtensionEditDeal from "./ExtensionEditDeal";
import ButtonWithText from "app/popleads/components/ui-kit/Button";
import { useExtensionContext } from "../ExtensionContext";
import { getDealName } from "../utils/deal-utils";

const DealsContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
`;

const DealsMenu = styled.div`
    min-width: 225px;
    height: 100%;
    border-right: 1px solid ${({ theme }) => theme.colors?.neutral?.[100] || "#E5E7EB"};
`;

const DealsContent = styled.div`
    width: 100%;
    height: 100%;
    padding: 24px;
    overflow-y: auto;
`;

const EditHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
`;

const ExtensionDealsPage = ({ isActive, setIsActive, isEditing, setIsEditing, selectedDeal, setSelectedDeal }) => {
    const { profile } = useExtensionContext();

    useEffect(() => {
        if (isActive && profile?.olanak) {
            const deal = profile.olanak.find((deal) => {
                if (!deal) return false;
                const dealName = getDealName(deal);
                return dealName === isActive;
            });
            if (deal) {
                setSelectedDeal(deal);
            }
        }
    }, [isActive, profile?.olanak, setSelectedDeal]);

    const handleEditClick = (deal) => {
        setSelectedDeal(deal);
        setIsEditing(true);
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
        setSelectedDeal(null);
    };

    return (
        <DealsContainer>
            <DealsMenu>
                <ExtensionDealSideBar isActive={isActive} setIsActive={setIsActive} />
            </DealsMenu>
            <DealsContent>
                {isEditing ? (
                    <>
                        <EditHeader>
                            <ButtonWithText
                                icon="arrow-left"
                                iconType="feather"
                                variant="neutral"
                                size="unstyled"
                                buttonStyle="borderless"
                                onClick={handleCancelEdit}
                            >
                                Cancel
                            </ButtonWithText>
                        </EditHeader>
                        <ExtensionEditDeal selectedDeal={selectedDeal} onCancel={handleCancelEdit} />
                    </>
                ) : (
                    <ExtensionViewDeal isActive={isActive} onEditClick={handleEditClick} />
                )}
            </DealsContent>
        </DealsContainer>
    );
};

export default ExtensionDealsPage;
