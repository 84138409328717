import { useState } from "react";
import { post } from "../networking/RequestService";
import moment from "moment";

const useGetTaskLists = () => {
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchTaskLists = async (customParams = {}) => {
        setLoading(true);
        setError(null);
        try {
            const res = await post({
                fn: "gorevListe",
                tarih: customParams.selectedDate ? moment(customParams.selectedDate).valueOf() : moment().valueOf(),
                old: 1,
                start: 0,
                startOld: 0,
                ...customParams,
            });
            setTasks(res.data);
        } catch (err) {
            setError(err);
            setTasks(null);
        } finally {
            setLoading(false);
        }
    };

    return { tasks, loading, error, refetch: fetchTaskLists, setTasks };
};

export default useGetTaskLists;
