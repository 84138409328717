import React, { useState } from "react";
import InputWithIcon from "./Input";
import styled from "styled-components";
import ButtonWithText from "./Button";
import { Heading5 } from "./Text";
import { colors } from "./colors";
import { post } from "app/popleads/networking/RequestService";
import { useExtensionContext } from "app/popleads/modules/extension/ExtensionContext";
import useAddAndRemoveTag from "app/popleads/hooks/useAddAndRemoveTag";
import { Spinner } from "react-bootstrap";
import EmptyStatus from "app/popleads/modules/extension/components/common/EmptyStatus";

const StyledButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const StyledInputContainer = styled.div`
    margin-top: 8px;
`;

const StyledCardTitle = styled(Heading5)`
    color: ${colors.neutral[600]};
    display: block;
`;

const StyledTagContainer = styled.div`
    margin-top: 8px;
    margin-bottom: 8px;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const TypeTag = () => {
    const { refetchProfile, profile } = useExtensionContext();
    const [searchText, setSearchText] = useState("");
    const { insertTag, removeTag, loading } = useAddAndRemoveTag(post, refetchProfile, profile?.id);
    const [isInputOpen, setIsInputOpen] = useState(false);

    const handleOpenInput = () => {
        setIsInputOpen(!isInputOpen);
    };

    return (
        <Wrapper>
            <StyledButtonContainer>
                <StyledCardTitle>Tags</StyledCardTitle>
                <ButtonWithText
                    icon={isInputOpen ? "remove" : "add"}
                    onClick={() => handleOpenInput()}
                    iconType="material"
                    variant="primary"
                    buttonStyle="default"
                    size="circle"
                />
            </StyledButtonContainer>
            {isInputOpen && (
                <StyledInputContainer>
                    <InputWithIcon
                        placeholder="Type tag"
                        type={"text"}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyDown={(e) => insertTag(e, searchText, setSearchText)}
                        rightIcon={loading && <Spinner animation="border" variant="primary" size="sm" />}
                    />
                </StyledInputContainer>
            )}
            {profile?.tag?.length > 0 ? (
                <StyledTagContainer>
                    {profile?.tag?.map((item) => (
                        <ButtonWithText
                            key={item?.id}
                            variant="neutral"
                            rightIcon="x"
                            buttonStyle="tag"
                            rightIconClick={() => removeTag(item?.id, item?.tagName)}
                            size="option"
                            //onClick={() => removeTag(item.id, item.tagName)}
                            iconVariant="danger"
                        >
                            {item?.tagName}
                        </ButtonWithText>
                    ))}
                </StyledTagContainer>
            ) : (
                !isInputOpen && <EmptyStatus description="Click the + to put a tag." />
            )}
        </Wrapper>
    );
};

export default TypeTag;
