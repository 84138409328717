import Switch from "app/popleads/components/ui-kit/Switch";
import React, { useState } from "react";

const SwitchComponent = () => {
    const [isActive, setIsActive] = useState();

    return (
        <div className="card card-custom mt-5">
            <div className="card-header">
                <h3 className="card-title">Card</h3>
            </div>
            <div className="card-body">
                <Switch isActive={isActive} setIsActive={setIsActive} />
            </div>
        </div>
    );
};

export default SwitchComponent;
