import React from "react";
import ExtensionCard from "app/popleads/components/ui-kit/ExtensionCard";
import ButtonWithText from "app/popleads/components/ui-kit/Button";
import { POPUP_CONTENTS } from "../utils/constants";
import { useExtensionContext } from "../ExtensionContext";

const ExtensionNoteCard = () => {
    const { setState, profileNotesRefetch, noteList } = useExtensionContext();

    const refetchProfileNotes = (rowsPerPage) => {
        profileNotesRefetch({ rowsPerPage: rowsPerPage });
    };

    return (
        <ExtensionCard
            button={
                <ButtonWithText
                    icon="add"
                    onClick={() => {
                        setState({ popupContent: POPUP_CONTENTS.NOTES });
                        refetchProfileNotes(100);
                    }}
                    iconType="material"
                    variant="primary"
                    buttonStyle="default"
                    size="circle"
                />
            }
            cardTitle={"Notes"}
            cardItems={noteList}
            viewMoreOnClick={() => {
                setState({ popupContent: POPUP_CONTENTS.NOTES });
                refetchProfileNotes(100);
            }}
            errorDescription="Click the + to create a note."
        />
    );
};

export default ExtensionNoteCard;
