import { useState, useCallback } from "react";
import { toast } from "react-toastify";

const useAddAndRemoveTag = (post, refetchProfile, profileId) => {
    const [loading, setLoading] = useState(false);

    const insertTag = useCallback(
        async (event, searchText, setSearchText) => {
            if (event.key === "Enter" && searchText.trim() !== "") {
                event.preventDefault();
                setSearchText("");

                const requestObj = {
                    fn: "tagInsert",
                    profilId: profileId,
                    tagName: searchText.trim(),
                };

                try {
                    setLoading(true);
                    const response = await post(requestObj);

                    if (response.status === 200) {
                        toast.success("Tag successfully added!");
                        refetchProfile();
                    } else {
                        toast.error("Failed to add tag.");
                    }
                } catch (error) {
                    toast.error("An error occurred while adding the tag.");
                } finally {
                    setLoading(false);
                }
            }
        },
        [post, refetchProfile, profileId]
    );

    const removeTag = useCallback(
        async (tagId, tagName) => {
            const requestObj = {
                fn: "tagDelete",
                profilId: profileId,
                tagName: tagName,
            };

            try {
                setLoading(true);
                const response = await post(requestObj);

                if (response.status === 200) {
                    toast.success("Tag successfully removed!");
                    refetchProfile();
                } else {
                    toast.error("Failed to remove tag.");
                }
            } catch (error) {
                toast.error("An error occurred while removing the tag.");
            } finally {
                setLoading(false);
            }
        },
        [post, refetchProfile, profileId]
    );

    return { insertTag, removeTag, loading };
};

export default useAddAndRemoveTag;
