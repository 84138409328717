import CustomDatePicker from "app/popleads/components/ui-kit/DateInput";
import React from "react";
import { connect } from "react-redux";

function DateInputComponent() {
    return (
        <div className="card card-custom mt-5">
            <div className="card-header">
                <h3 className="card-title">DateInput</h3>
            </div>
            <div className="card-body">
                <CustomDatePicker
                    leftIcon={"calendar"}
                    rightIcon={"x"}
                    className="w-100 form-control"
                    placeholder={"mm/dd/yyyy"}
                    // leftIconClick={console.log("leftIcon run")}
                    rightIconClick={() => alert("tıklandı")}
                />
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DateInputComponent);
