import { colors } from "app/popleads/components/ui-kit/colors";
import InputWithIcon from "app/popleads/components/ui-kit/Input";
import { post } from "app/popleads/networking/RequestService";
import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import styled from "styled-components";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "_metronic/_helpers";

const SearchContainer = styled.div`
    position: relative;
    width: 100%;
`;

const ResultsList = styled.div`
    border: 1px solid #ccc;
    border-radius: 8px;
    background: #fff;
    padding: 15px;
    position: absolute;
    top: 50px;
    width: 100%;
    z-index: 999;
    display: ${(props) => (props.isOpen ? "block" : "none")};
    max-height: 300px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
`;

const ResultGroup = styled.div`
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }
`;

const ResultItem = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    padding: 2px 0;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
        background-color: #f1f1f1;
    }

    &:last-child {
        margin-bottom: 0;
    }
`;

const CategoryTitle = styled.div`
    font-size: 12px;
    color: ${(props) => (props.category === "lead" ? colors.success[900] : colors.warning[900])};
    text-transform: uppercase;
    opacity: 1;
    margin-bottom: 8px;
    font-weight: 400;
`;

const Avatar = styled.img`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 8px;
`;

const AvatarSvg = styled(SVG)`
    border-radius: 50%;
    min-width: 24px;
    min-height: 24px;
    margin-right: 8px;
`;

const ResultText = styled.div`
    display: flex;
    align-items: baseline;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const Name = styled.div`
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.17px;
    margin-right: 12px;
`;

const Info = styled.div`
    font-size: 12px;
    color: #8b999f;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const Highlighted = styled.span`
    letter-spacing: -0.17px;
    color: #0085be;
    font-weight: 500;
`;

export default function ExtensionSearch({ optionClick }) {
    const [searchText, setSearchText] = useState("");
    const [filteredResults, setFilteredResults] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const wrapperRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setIsOpen(false);
                // clearSearch();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleSearch = async (e) => {
        const value = e.target.value;
        setSearchText(value);

        let requestObj = {
            fn: "ara",
            kelime: value,
        };

        try {
            setLoading(true);
            const response = await post(requestObj);

            if (response.status === 200) {
                const profilListe = response.data.profilListe.filter((item) => !!item.id) || [];

                const filtered = profilListe.filter(
                    (profile) =>
                        profile?.ad?.toLowerCase().includes(value.toLowerCase()) ||
                        profile?.soyad?.toLowerCase().includes(value.toLowerCase())
                );
                setFilteredResults(filtered);
                setLoading(false);
                setIsOpen(true);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    };
    const handleResultClick = (result) => {
        setSearchText(`${result.ad} ${result.soyad}`);
        setIsOpen(false); // Arama sonuçlarını kapat
        optionClick(result.id); // Orijinal işlev çağrısı
    };
    const clearSearch = () => {
        setSearchText("");
        setFilteredResults([]);
        setIsOpen(false);
    };

    const highlightText = (text, search) => {
        if (!search) return text;
        const regex = new RegExp(`(${search})`, "gi");
        const parts = text?.split(regex);

        return parts?.map((part, index) => (regex.test(part) ? <Highlighted key={index}>{part}</Highlighted> : part));
    };

    return (
        <SearchContainer ref={wrapperRef}>
            <InputWithIcon
                placeholder="Search Leads and Contacts"
                type={"text"}
                leftIcon={"search"}
                value={searchText}
                onChange={handleSearch}
                disabled={loading}
                rightIcon={
                    searchText.length > 0 && loading ? (
                        <Spinner animation="border" variant="primary" size="sm" />
                    ) : searchText.length > 0 && !loading ? (
                        "x"
                    ) : null
                }
                rightIconClick={clearSearch}
                onFocus={() => setIsOpen(filteredResults.length > 0)}
                onBlur={() => setTimeout(() => setIsOpen(false), 200)}
            />

            <ResultsList isOpen={isOpen}>
                {["lead", "contact"].map((category) => {
                    const categoryResults = filteredResults.filter((result) => result.kategori === category);

                    if (categoryResults.length === 0) return null;

                    return (
                        <ResultGroup key={category}>
                            <CategoryTitle category={category}>
                                {category === "lead" ? "LEAD" : "CONTACT"}
                            </CategoryTitle>
                            {categoryResults.map((result, index) => (
                                <ResultItem key={index} onClick={() => handleResultClick(result)}>
                                    {result.avatar ? (
                                        <Avatar src={result.avatar} alt={result.ad} />
                                    ) : (
                                        <AvatarSvg src={toAbsoluteUrl("/media/svg/icons/Extension/user-avatar.svg")} />
                                    )}
                                    <ResultText>
                                        <Name>
                                            {highlightText(result.ad, searchText)}{" "}
                                            {highlightText(result.soyad, searchText)}
                                        </Name>
                                        <Info>
                                            {result.sirket && result.sirket}
                                            {result.sirket && result.sektor && " | "}
                                            {result.sektor && result.sektor}
                                        </Info>
                                    </ResultText>
                                </ResultItem>
                            ))}
                        </ResultGroup>
                    );
                })}
                {filteredResults.length === 0 && <div>Result not found.</div>}
            </ResultsList>
        </SearchContainer>
    );
}
