import React from "react";
import ExtensionNewTask from "./ExtensionNewTask";
import { NewDealContainer, NewDealContentWrapper } from "./ExtensionNewDealPage";

const ExtensionNewTaskPage = ({ taskPage }) => {
    return (
        <>
            <NewDealContainer>
                <NewDealContentWrapper>
                    <ExtensionNewTask />
                </NewDealContentWrapper>
            </NewDealContainer>
        </>
    );
};

export default ExtensionNewTaskPage;
