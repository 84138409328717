import { useState } from "react";
import { post } from "../networking/RequestService";
import { toast } from "react-toastify";
import moment from "moment";

const useGetCompletedTasks = () => {
    const [completedTasks, setCompletedTasks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const getCompletedTasks = async ({ selectedDate }) => {
        setLoading(true);
        setError(null);
        try {
            const response = await post({
                fn: "completedTasks",
                tarih: selectedDate ? moment(selectedDate).valueOf() : moment().valueOf(),
            });

            if (response) {
                setCompletedTasks(response.data);
                return response.data;
            } else {
                throw new Error(response?.message || "Failed to fetch completed tasks");
            }
        } catch (err) {
            setError(err.message);
            toast.error(err.message);
            return [];
        } finally {
            setLoading(false);
        }
    };

    return {
        loading,
        error,
        completedTasks,
        getCompletedTasks,
    };
};

export default useGetCompletedTasks;
