import React from "react";
import styled from "styled-components";
import ButtonWithText from "./Button";
import { colors } from "./colors";

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #384d567a;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
`;

const ModalContainer = styled.div`
    background-color: white;
    border-radius: 8px;
    max-width: 500px;
    width: 90%;
    position: relative;
    box-shadow: -1px 2px 12px #001b2633;
`;

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
`;

const ModalFooter = styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 12px;
    background-color: ${colors.white};
    border-radius: 0px 0px 8px 8px;
    border-top: 1px solid #eef2f3;
`;

const ModalBody = styled.div`
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.14px;
    color: ${colors.neutral[800]};
    opacity: 1;
`;

const ModalTitle = styled.h2`
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.14px;
    color: ${colors.neutral[900]};
    opacity: 1;
    margin: 0;
`;

const ModalHeaderBody = styled.div`
    padding: 24px;
    background-color: #fbfcfc;
    border-radius: 8px 8px 0px 0px;
    gap: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Modal = ({ isOpen, onClose, children, title, variant, icon, onSuccess }) => {
    if (!isOpen) return null;

    return (
        <ModalOverlay onClick={onClose}>
            <ModalContainer onClick={(e) => e.stopPropagation()}>
                <ModalHeaderBody>
                    <ModalHeader>
                        {icon && (
                            <span
                                data-testid={`material-${icon}`}
                                className="material-symbols-outlined"
                                style={{
                                    color: variant ? colors[variant][900] : colors.primary[900],
                                    fontSize: "32px",
                                    marginBottom: "20px",
                                }}
                            >
                                {icon}
                            </span>
                        )}
                        <ModalTitle>{title}</ModalTitle>
                    </ModalHeader>
                    <ModalBody>{children}</ModalBody>
                </ModalHeaderBody>
                <ModalFooter>
                    <ButtonWithText variant="passive" buttonStyle="borderless" size="small" onClick={onClose}>
                        Not Now
                    </ButtonWithText>
                    <ButtonWithText variant={variant} buttonStyle="default" size="small" onClick={onSuccess}>
                        Yes, Delete
                    </ButtonWithText>
                </ModalFooter>
            </ModalContainer>
        </ModalOverlay>
    );
};

export default Modal;
