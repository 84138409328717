import { useCallback, useEffect, useMemo, useState } from "react";
import { post } from "../networking/RequestService";

function useGetCompanyUsers(params = {}, autoFetch = true) {
    const [companyUsers, setCompanyUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const memoizedParams = useMemo(() => params, [JSON.stringify(params)]);

    const fetchCompanyUsers = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await post({ fn: "companyUsers", ...memoizedParams });
            setCompanyUsers(response.data.users);
        } catch (error) {
            setError(error.response?.data?.text || "An unexpected error occurred");
        } finally {
            setLoading(false);
        }
    }, [memoizedParams]);

    useEffect(() => {
        if (autoFetch) {
            fetchCompanyUsers();
        }
    }, [fetchCompanyUsers, autoFetch]);

    return { companyUsers, loading, error, fetchCompanyUsers };
}

export default useGetCompanyUsers;
