import styled from "styled-components";
import { colors } from "./colors";

const TextAreaInput = styled.textarea`
    width: 100%;
    height: 150px;
    border: 1px solid ${colors.neutral[300]};
    border-radius: 8px;
    padding: 8px;
    outline: none;
    resize: none;
    font-size: 14px;
    line-height: 1.5;
    color: ${colors.neutral[800]};

    &:focus {
        border: 2px solid ${colors.primary[900]};
        padding: 10px 14px;
    }
`;

export default TextAreaInput;
