import { Formik, Form } from "formik";
import { colors } from "app/popleads/components/ui-kit/colors";
import React from "react";
import styled from "styled-components";
import ExtensionInputGroup from "./ExtensionInputGroup";
import { useExtensionContext } from "../ExtensionContext";
import { dealMarketingTypesOptions, dealChannelTypesOptions } from "../utils/constants";
import useCompanyData from "app/popleads/hooks/useCompanyData";

export const StyledLabel = styled.label`
    display: block;
    font-size: 12px;
    color: ${colors.neutral[600]};
    margin-bottom: 0px;

    ${(props) =>
        props.required &&
        `
    &:after {
      content: '*';
      color: #ff4d4f;
      margin-left: 4px;
    }
  `}

    ${(props) =>
        props.disabled &&
        `
    color: #999;
    cursor: not-allowed;
  `}
`;

const ExtensionEditDeal = ({ selectedDeal }) => {
    const { setDealObject, dealObject } = useExtensionContext();
    const { companyUsers } = useCompanyData();

    const partnerOptions = React.useMemo(() => {
        return companyUsers.map((user) => ({
            label: user.name,
            value: user.id,
        }));
    }, [companyUsers]);

    React.useEffect(() => {
        if (selectedDeal) {
            const updatedDeal = {
                ...selectedDeal,
                closingDate: selectedDeal.kapanmaTarihi || "",
                channelType: selectedDeal.dealTypeChannel || "",
                department: selectedDeal.departmentName || "",
                partner: selectedDeal.partnerName || "",
            };
            setDealObject(updatedDeal);
        }
        return () => {
            setDealObject({});
        };
    }, [selectedDeal, setDealObject]);

    const initialValues = {
        dealName: selectedDeal?.dealName || "",
        courseName: selectedDeal?.courseName || "",
        accountName: selectedDeal?.accountName || "",
        amount: selectedDeal?.amount || 0,
        paidAmount: selectedDeal?.paidAmount || "",
        closingDate: selectedDeal?.kapanmaTarihi || "",
        type: selectedDeal?.type || "",
        channelType: selectedDeal?.dealTypeChannel || "",
        department: selectedDeal?.departmentName || "",
        partner: selectedDeal?.partnerName || "",
    };

    return (
        <Formik initialValues={initialValues}>
            {({ values, errors, touched, handleChange, setFieldValue }) => {
                return (
                    <Form style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                        <ExtensionInputGroup
                            label="Deal Name"
                            type="input"
                            value={dealObject.dealName}
                            onChange={(value) => {
                                setFieldValue("dealName", value);
                                setDealObject({ ...dealObject, dealName: value });
                            }}
                        />

                        <ExtensionInputGroup
                            label="Course Name"
                            type="input"
                            value={dealObject.courseName}
                            onChange={(value) => {
                                setFieldValue("courseName", value);
                                setDealObject({ ...dealObject, courseName: value });
                            }}
                        />

                        <ExtensionInputGroup
                            label="Account Name"
                            type="input"
                            value={dealObject.accountName}
                            onChange={(value) => {
                                setFieldValue("accountName", value);
                                setDealObject({ ...dealObject, accountName: value });
                            }}
                        />

                        <ExtensionInputGroup
                            label="Amount"
                            type="input"
                            inpType="number"
                            leftIcon="dollar-sign"
                            value={dealObject.amount}
                            onChange={(value) => {
                                setFieldValue("amount", value);
                                setDealObject({ ...dealObject, amount: value });
                            }}
                        />

                        <ExtensionInputGroup
                            label="Paid Amount"
                            type="input"
                            inpType="number"
                            leftIcon="dollar-sign"
                            value={dealObject.paidAmount}
                            onChange={(value) => {
                                setFieldValue("paidAmount", value);
                                setDealObject({ ...dealObject, paidAmount: value });
                            }}
                        />

                        <ExtensionInputGroup
                            label="Closing Date"
                            type="date"
                            value={dealObject.closingDate || dealObject.kapanmaTarihi}
                            onChange={(selected) => {
                                setFieldValue("closingDate", selected);
                                setDealObject({ ...dealObject, closingDate: selected, kapanmaTarihi: selected });
                            }}
                        />

                        <ExtensionInputGroup
                            label="Type"
                            type="select"
                            options={dealMarketingTypesOptions}
                            value={dealObject.type ? { label: dealObject.type, value: dealObject.type } : ""}
                            onChange={(selected) => {
                                setFieldValue("type", selected.value);
                                setDealObject({ ...dealObject, type: selected.value });
                            }}
                        />

                        <ExtensionInputGroup
                            label="Marketing Channel"
                            type="select"
                            options={dealChannelTypesOptions}
                            value={
                                dealObject.dealTypeChannel
                                    ? { label: dealObject.dealTypeChannel, value: dealObject.dealTypeChannel }
                                    : ""
                            }
                            onChange={(selected) => {
                                setFieldValue("channelType", selected.value);
                                setDealObject({
                                    ...dealObject,
                                    channelType: selected.value,
                                    dealTypeChannel: selected.value,
                                });
                            }}
                        />

                        <ExtensionInputGroup
                            label="Department"
                            type="input"
                            value={dealObject.department || dealObject.departmentName}
                            onChange={(value) => {
                                setFieldValue("department", value);
                                setDealObject({ ...dealObject, department: value, departmentName: value });
                            }}
                        />

                        <ExtensionInputGroup
                            label="Partner"
                            type="select"
                            options={partnerOptions}
                            onChange={(selected) => {
                                setFieldValue("partner", selected.value);
                                setDealObject({
                                    ...dealObject,
                                    partner: selected.value,
                                    partnerName: selected.label,
                                });
                            }}
                            value={
                                dealObject.partner
                                    ? {
                                          label:
                                              companyUsers.find((user) => user.id === dealObject.partner)?.name || "",
                                          value: dealObject.partner,
                                      }
                                    : ""
                            }
                        />
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ExtensionEditDeal;
