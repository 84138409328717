import { toAbsoluteUrl } from "_metronic/_helpers";
import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import styled, { createGlobalStyle } from "styled-components";
import SVG from "react-inlinesvg";
import Tabs from "app/popleads/components/ui-kit/Tabs";
import ProfileCard from "app/popleads/components/ui-kit/ProfileCard";
import { Spinner } from "react-bootstrap";
import { colors } from "app/popleads/components/ui-kit/colors";
import ButtonWithText from "app/popleads/components/ui-kit/Button";
import ExtensionAboutPage from "./components/ExtensionAboutPage";
import Modal from "app/popleads/components/ui-kit/Modal";
import { ExtensionProvider, useExtensionContext } from "./ExtensionContext";
import ExtensionSearch from "./components/ExtensionSearch";
import { POPUP_CONTENTS } from "./utils/constants";
import ExtensionCoursesPage from "./components/ExtensionCoursesPage";
import ExtensionDealsPage from "./components/ExtensionDealsPage";
import ExtensionNewDealPage from "./components/ExtensionNewDealPage";
import TypeTag from "app/popleads/components/ui-kit/TypeTag";
import ButtonWithLabel from "app/popleads/components/ui-kit/ButtonWithLabel";
import ExtensionCourseCard from "./components/ExtensionCourseCard";
import ExtensionDealCard from "./components/ExtensionDealCard";
import ExtensionNoteCard from "./components/ExtensionNoteCard";
import ExtensionNotesPage from "./components/ExtensionNotesPage";
import ExtensionNewTaskPage from "./components/ExtensionNewTaskPage";
import ExtensionTaskCard from "./components/ExtensionTaskCard";
import useTaskDelete from "app/popleads/hooks/useTaskDelete";
import useGetTaskLists from "app/popleads/hooks/useGetTaskLists";
import useUnpopProfile from "app/popleads/hooks/useUnpopProfile";
import ExtensionTaskList from "./components/ExtensionTaskList";
import ExtensionFeedList from "./components/ExtensionFeedList";
import { toast } from "react-toastify";
import { getDealName } from "./utils/deal-utils";

const headerHeight = 57;
//const profileContentHeight = 155;

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #F8F8F8 !important;
    /* extensionu iframe'de açarken, arkaplanın şeffaf olması için ileride değiştirilecekç */
  }
`;

const ExtensionContainer = styled.div`
    width: 375px;
    height: calc(100%);
    display: flex;
    flex-direction: column;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow: hidden;
`;

const ExtensionHeader = styled.div`
    width: 100%;
    height: ${headerHeight}px;
    background-color: #fff;
    padding: 8px 12px;
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid ${colors.neutral[100]};
`;

const ExtensionHeaderContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-self: center;
`;

const ExtensionHeaderLogo = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;

const ExtensionHeaderIcons = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
`;

const StyledSVGWrapper = styled.div`
    cursor: pointer;
`;

const ExtensionContentWrapper = styled.div`
    width: 100%;
    height: calc(100vh - ${headerHeight}px);
    background-color: #fbfcfc;
    border-radius: 0 0 8px 8px;
    padding: 12px 0px;
    position: relative;
`;

/*const ExtensionFooter = styled.div`
    width: 100%;
    min-height: 50px;
    background-color: #fff;
    border-radius: 0 0 8px 8px;
`;*/

const ExtensionPopup = styled.div`
    width: ${(props) =>
        props.variant === "Deals"
            ? "662px"
            : props.variant === "New Deal"
            ? "640px"
            : props.variant === "Notes"
            ? "621px"
            : "412px"};
    height: 100%;
    background-color: #fbfcfc;
    margin-right: 8px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
`;

const ExtensionWithPopup = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const ExtensionPopupHeader = styled.div`
    width: 100%;
    height: 56px;
    background-color: #fff;
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid ${colors.neutral[100]};
`;

const ExtensionPopupHeaderTitle = styled.div`
    color: ${colors.neutral[700]};
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    letter-spacing: -0.13px;
`;

const ExtensionPopupContent = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: ${(props) =>
        props.variant === "New Deal"
            ? "calc(100% - 56px)"
            : props.variant === "Deals"
            ? "calc(100% - 56px - 50px)"
            : props.variant === "Courses"
            ? "calc(100% - 56px - 24px)"
            : props.variant === "Notes"
            ? "calc(100% - 56px)"
            : props.variant === "New Task"
            ? "calc(100% - 56px)"
            : "calc(100% - 56px - 50px - 24px)"};
    background-color: #fbfcfc;

    ${(props) =>
        props.variant === "New Task" || props.variant === "Notes"
            ? `border-radius: 0 0 8px 8px; 
        border-bottom: 1px solid ${colors.neutral[100]};
        overflow: hidden;`
            : ""}
`;

const ExtensionPopupFooter = styled.div`
    width: 100%;
    height: 50px;
    background-color: ${colors.white};
    padding: 8px 12px;
    border-top: 1px solid ${colors.neutral[100]};
    border-radius: 0 0 8px 8px;
`;

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #384d567a;
    z-index: 1000;
`;

const InputWrapper = styled.div`
    width: 100%;
    position: fixed;
    max-width: 350px;
    z-index: 1001;
    margin: 0 auto;
`;

const ProfileLoadingOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
`;

function ExtensionHomeContent() {
    const {
        selectedTab,
        popupContent,
        setState,
        profile,
        isProfileLoading,
        updateProfileLoading,
        selectedModal,
        modals,
        selectedDate,
        isProfileNotFound,
    } = useExtensionContext();
    const { tasks: taskList, loading: taskListLoading, refetch: fetchTaskLists } = useGetTaskLists();
    const { deleteTask } = useTaskDelete();
    const [taskToDelete, setTaskToDelete] = useState(null);
    const { unpopProfile } = useUnpopProfile();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [selectedDeal, setSelectedDeal] = useState(null);

    const handleTaskDelete = async (id) => {
        try {
            await deleteTask(id);
            setState({ selectedModal: null });
            fetchTaskLists();
        } catch (error) {}
    };

    const handleProfileDelete = async () => {
        try {
            const { error } = await unpopProfile();
            if (!error) {
                toast.success("Profile successfully deleted");
            } else {
                toast.error(error);
            }
        } catch (error) {
            toast.error(error?.message || "An error occurred while deleting the profile");
        } finally {
            setIsDeleteModalOpen(false);
        }
    };

    const options = useMemo(
        () => [
            { value: "Feed" },
            { value: "Profile", disabled: Boolean(isProfileNotFound) },
            { value: "Tasks", disabled: Boolean(isProfileNotFound) },
        ],
        [isProfileNotFound]
    );

    useEffect(() => {
        if (selectedTab === "Tasks" && !isActive) {
            fetchTaskLists({ selectedDate });
        }
    }, [selectedTab, selectedDate, isActive]);

    useEffect(() => {
        if (isProfileNotFound) {
            setState({ selectedTab: "Feed" });
        }
    }, [isProfileNotFound]);

    const toggleSearch = () => {
        setIsSearchVisible(!isSearchVisible);
    };

    const searchInputOptionClick = (id) => {
        window.open(`/extension?profileId=${id}`, "_blank");
    };
    const DealFooter = () => {
        return (
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <ButtonWithLabel
                    variant="primary"
                    buttonStyle="default"
                    size="circle"
                    icon={"add"}
                    iconType={"material"}
                    label={"Create New Potential"}
                    onClick={() => setState({ popupContent: POPUP_CONTENTS.NEW_DEAL })}
                />

                <div style={{ display: "flex", gap: "8px", alignItems: "center", justifyContent: "center" }}>
                    <ButtonWithText variant="danger" buttonStyle="borderless" size="small">
                        Delete Deal
                    </ButtonWithText>
                    <ButtonWithText
                        variant="primary"
                        buttonStyle="default"
                        type="default"
                        size="small"
                        onClick={() => {
                            const deal = profile?.olanak?.find((deal) => {
                                if (!deal) return false;
                                const dealName = getDealName(deal);
                                return dealName === isActive;
                            });
                            if (deal) {
                                setSelectedDeal(deal);
                                setIsEditing(true);
                            }
                        }}
                    >
                        Edit Deal
                    </ButtonWithText>
                </div>
            </div>
        );
    };

    return (
        <>
            <GlobalStyle />
            <ExtensionWithPopup>
                {popupContent && (
                    <ExtensionPopup variant={popupContent}>
                        <ExtensionPopupHeader>
                            <ExtensionPopupHeaderTitle>{popupContent}</ExtensionPopupHeaderTitle>
                            <ButtonWithText
                                icon="close"
                                iconType="material"
                                variant="passive"
                                size="medium"
                                buttonStyle="borderless"
                                hover={false}
                                onClick={() => {
                                    //setPopupContent(null);
                                    setState({ popupContent: null });
                                }}
                            />
                        </ExtensionPopupHeader>
                        <ExtensionPopupContent variant={popupContent}>
                            {popupContent === POPUP_CONTENTS.ABOUT ? (
                                <ExtensionAboutPage />
                            ) : popupContent === POPUP_CONTENTS.COURSES ? (
                                <ExtensionCoursesPage />
                            ) : popupContent === POPUP_CONTENTS.DEALS ? (
                                <ExtensionDealsPage
                                    isActive={isActive}
                                    setIsActive={setIsActive}
                                    isEditing={isEditing}
                                    setIsEditing={setIsEditing}
                                    selectedDeal={selectedDeal}
                                    setSelectedDeal={setSelectedDeal}
                                />
                            ) : popupContent === POPUP_CONTENTS.NEW_DEAL ? (
                                <ExtensionNewDealPage isActive={isActive} setIsActive={setIsActive} />
                            ) : popupContent === POPUP_CONTENTS.NOTES ? (
                                <ExtensionNotesPage />
                            ) : popupContent === POPUP_CONTENTS.NEW_TASK ? (
                                <ExtensionNewTaskPage />
                            ) : (
                                <h1>Feed</h1>
                            )}
                        </ExtensionPopupContent>
                        {popupContent === "Deals" && (
                            <ExtensionPopupFooter>
                                <DealFooter />
                            </ExtensionPopupFooter>
                        )}
                    </ExtensionPopup>
                )}
                <ExtensionContainer>
                    <ExtensionHeader>
                        <ExtensionHeaderContent>
                            {isSearchVisible ? (
                                <>
                                    <Overlay onClick={toggleSearch} />
                                    <InputWrapper>
                                        <ExtensionSearch optionClick={searchInputOptionClick} />
                                    </InputWrapper>
                                </>
                            ) : (
                                <>
                                    <ExtensionHeaderLogo>
                                        <SVG
                                            src={toAbsoluteUrl("/media/svg/icons/Navigation/popleads_logo.svg")}
                                            height={40}
                                        />
                                    </ExtensionHeaderLogo>
                                    <ExtensionHeaderIcons>
                                        <StyledSVGWrapper>
                                            <SVG
                                                src={toAbsoluteUrl("/media/svg/icons/Extension/search.svg")}
                                                onClick={toggleSearch}
                                            />
                                        </StyledSVGWrapper>
                                        <StyledSVGWrapper>
                                            <SVG src={toAbsoluteUrl("/media/svg/icons/Extension/keyboard_tap.svg")} />
                                        </StyledSVGWrapper>
                                        <StyledSVGWrapper>
                                            <SVG
                                                style={{
                                                    fill:
                                                        profile?.kategori === "lead"
                                                            ? `${colors.success[700]}`
                                                            : `${colors.warning[700]}`,
                                                }}
                                                src={toAbsoluteUrl("/media/svg/icons/Extension/pop-it.svg")}
                                            />
                                        </StyledSVGWrapper>
                                        <StyledSVGWrapper>
                                            <SVG
                                                src={toAbsoluteUrl("/media/svg/icons/Extension/delete.svg")}
                                                onClick={() => setIsDeleteModalOpen(true)}
                                            />
                                        </StyledSVGWrapper>
                                    </ExtensionHeaderIcons>
                                </>
                            )}
                        </ExtensionHeaderContent>
                    </ExtensionHeader>
                    <ExtensionContentWrapper>
                        <>
                            {(isProfileLoading || updateProfileLoading) && (
                                <ProfileLoadingOverlay>
                                    <Spinner animation="border" variant="primary" />
                                </ProfileLoadingOverlay>
                            )}
                            <div
                                style={{
                                    padding: "0px 12px",
                                    marginBottom: "25px",
                                }}
                            >
                                <Tabs
                                    options={options}
                                    selected={selectedTab}
                                    setSelected={(selected) => setState({ selectedTab: selected })}
                                />
                            </div>
                            {selectedTab === "Profile" ? (
                                <>
                                    <div
                                        style={{
                                            padding: "0px 12px",
                                            marginBottom: "25px",
                                        }}
                                    >
                                        <ProfileCard />
                                    </div>
                                    <div
                                        style={{
                                            height: "calc(100% - 165px)",
                                            display: "flex",
                                            flexDirection: "column",
                                            flex: 1,
                                            gap: "25px",
                                            overflowY: "auto",
                                            padding: "0px 12px",
                                        }}
                                    >
                                        <ExtensionCourseCard />
                                        <ExtensionDealCard />
                                        <ExtensionNoteCard />
                                        <TypeTag />
                                        <ExtensionTaskCard setTaskToDelete={setTaskToDelete} />
                                    </div>
                                </>
                            ) : selectedTab === "Feed" ? (
                                <>
                                    <ExtensionFeedList />
                                </>
                            ) : (
                                selectedTab === "Tasks" && (
                                    <>
                                        <ExtensionTaskList
                                            taskList={taskList}
                                            taskListLoading={taskListLoading}
                                            setTaskToDelete={setTaskToDelete}
                                            isActive={isActive}
                                            setIsActive={setIsActive}
                                        />
                                    </>
                                )
                            )}
                        </>
                    </ExtensionContentWrapper>
                </ExtensionContainer>
                <div id="newFilterMenu" />
            </ExtensionWithPopup>
            <Modal
                isOpen={isDeleteModalOpen}
                onClose={() => setIsDeleteModalOpen(false)}
                title="Are you sure you want to delete this profile?"
                variant="danger"
                icon="delete"
                onSuccess={handleProfileDelete}
            >
                <div>All related notes and tasks will be also deleted. </div>
                <div>This action cannot be undone.</div>
            </Modal>
            <Modal
                isOpen={selectedModal === modals.DELETE_TASK}
                onClose={() => setState({ selectedModal: null })}
                title="Are you sure you want to delete this file?"
                variant="danger"
                icon="delete"
                onSuccess={() => handleTaskDelete(taskToDelete)}
            >
                <div>This action cannot be undone.</div>
            </Modal>
        </>
    );
}

function ExtensionHome(props) {
    return (
        <ExtensionProvider extensionEvents={{}}>
            <ExtensionHomeContent {...props} />
        </ExtensionProvider>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ExtensionHome);
