import React from "react";
import styled from "styled-components";
import { colors } from "./colors";

const LabelText = styled.label`
    font-size: 11px;
    font-weight: 500;
    color: ${({ variant }) => colors[variant]?.[900]};
    background-color: ${({ variant }) => colors[variant]?.[100]};
    border: 1px solid ${({ variant }) => colors[variant]?.[900]};
    display: inline-block;
    padding: 4px 8px;
    border-radius: 6px;
    margin: 0;
`;

const Label = ({ children, variant = "neutral" }) => {
    return <LabelText variant={variant}>{children}</LabelText>;
};

export default Label;
