import React from "react";
import styled from "styled-components";
import ButtonWithText from "./Button";
import { colors } from "./colors";
import { rgba } from "polished";

const Label = styled.span`
    color: ${({ variant }) => colors[variant][900]};
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
`;

const StyledButton = styled(ButtonWithText)``;

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    &:hover {
        ${Label} {
            text-decoration: underline;
            color: ${({ variant = "primary" }) => colors[variant][950]};
        }

        ${StyledButton} {
            background-color: ${({ variant = "primary" }) => colors[variant][950]};
            box-shadow: 0 4px 8px ${({ variant = "primary" }) => rgba(colors[variant][950], 0.2)};
        }
    }

    &:active {
        ${Label} {
            color: ${({ variant = "primary" }) => colors[variant][900]};
        }

        ${StyledButton} {
            background-color: ${({ variant = "primary" }) => colors[variant][900]};
            color: ${({ variant = "primary" }) => colors[variant][200]};
            box-shadow: inset 0 2px 4px ${({ variant = "primary" }) => rgba(colors[variant][950], 0.2)};
        }
    }
`;

const ButtonWithLabel = ({ label, labelPosition = "right", children, ...props }) => {
    const content = (
        <>
            <StyledButton {...props}>{children}</StyledButton>
            <Label {...props}>{label}</Label>
        </>
    );

    return <Container {...props}>{labelPosition === "left" ? content.reverse() : content}</Container>;
};

export default ButtonWithLabel;
