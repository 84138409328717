import React from "react";
import styled from "styled-components";
import { colors } from "./colors";

const SwitchWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    width: 52px;
    height: 28px;
    background-color: ${(props) => (props.isActive ? colors.primary[900] : colors.neutral[200])};
    border-radius: 14px;
    padding: 2px;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s ease-in-out;

    &::before {
        content: "";
        position: absolute;
        width: 1.37px;
        height: 10px;
        background-color: white;
        opacity: ${(props) => (props.isActive ? 1 : 0)};
        transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

        top: 50%;
        left: ${(props) => (props.isActive ? "32%" : "50%")};
        transform: translate(-50%, -50%);
    }

    /* Adding the text labels */
    &::after {
        content: "${(props) => !props.isActive && "O"}";
        position: absolute;
        top: 50%;
        ${(props) => (props.isActive ? "left: 10px;" : "right: 10px;")}
        transform: translateY(-50%);
        color: ${colors.neutral[500]};
        font-size: 13px;
    }
`;

const SwitchCircle = styled.div`
    width: 24px;
    height: 24px;
    background-color: ${colors.white};
    border-radius: 50%;
    transition: transform 0.3s ease-in-out;
    transform: ${(props) => (props.isActive ? "translateX(24px)" : "translateX(0)")};
`;

const Switch = ({ isActive, setIsActive }) => {
    const toggleSwitch = () => {
        setIsActive((prev) => !prev);
    };

    return (
        <SwitchWrapper isActive={isActive} onClick={toggleSwitch} role="button" aria-pressed={isActive}>
            <SwitchCircle isActive={isActive} />
        </SwitchWrapper>
    );
};

export default Switch;
