import { useState } from "react";
import { post } from "../networking/RequestService";

const useOlanakEkle = (profileId) => {
    const [olanak, setOlanak] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const olanakEkle = async ({
        profil,
        ad,
        units = 1,
        dealCampaignType,
        kapanmaTarihi,
        accountName,
        amount,
        durum,
        lastDurum,
        type,
        paid,
        inquiryActivityId,
        typeChannel,
        courseName,
        newCourseNameForInhouse = 0,
        partnerUserId,
    }) => {
        setLoading(true);
        setError(null);
        try {
            const res = await post({
                fn: "olanakEkle",
                profil,
                ad,
                units,
                dealCampaignType,
                kapanmaTarihi,
                accountName,
                amount,
                durum,
                lastDurum,
                type,
                paid,
                inquiryActivityId,
                typeChannel,
                courseName,
                newCourseNameForInhouse,
                partnerUserId,
            });

            const profile = res.data.profil;
            profile.inhouseInquiries = profile.olanak
                .filter((item) => item.activityType === "In-house Inquiry")
                .map((item) => ({
                    label: item.activityName,
                    value: item.activityId,
                }));
            //console.log("profile", profile)

            setOlanak(profile);
            return res;
        } catch (err) {
            setError(err);
            setOlanak(null);
            return err;
        } finally {
            setLoading(false);
        }
    };

    return { olanak, loading, error, olanakEkle };
};

export default useOlanakEkle;
