import { toAbsoluteUrl } from "_metronic/_helpers";
import { colors } from "app/popleads/components/ui-kit/colors";
import Tabs from "app/popleads/components/ui-kit/Tabs";
import { Body2, Heading4 } from "app/popleads/components/ui-kit/Text";
import React from "react";
import SVG from "react-inlinesvg";
import { FormattedNumber } from "react-intl";
import styled from "styled-components";
//todo: StyledAmount componentinde tasarımda fontsize 18 px. onun yerine heading4 kullanıldı. font-weight o yüzden kullanıldı
//todo: tasarımda #00856C rengi olmadığı için değişken şeklinde tanımlanmadı

const ProgressBarWrapper = styled.div`
    width: 5px;
    height: 45px;
    background-color: ${colors.neutral[200]};
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    margin-right: 16px;
`;

const ProgressBarFill = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: ${({ progress }) => progress}%;
    background-color: ${({ color }) => color};
`;
const StyledTabWrapper = styled("div")`
    display: flex;
    align-items: center;
    height: 60px;
`;
const StyledOptionLabel = styled(Body2)`
    color: ${({ isActive }) => (isActive ? colors.neutral[700] : colors.neutral[500])};
    display: block;
`;

const StyledAmount = styled(Heading4)`
    color: ${({ color }) => color};
    display: block;
    margin-top: 4px;
`;
const StyledContainer = styled.div`
    margin: 8px 0px;
`;
const ProgressBar = ({ color, progress }) => (
    <ProgressBarWrapper>
        <ProgressBarFill color={color} progress={progress} />
    </ProgressBarWrapper>
);

const CustomTabContent = (option, isActive) => (
    <StyledTabWrapper>
        {option.icon && <div className="mr-4">{option.icon}</div>}
        <ProgressBar color={option.color} progress={option.progress} />
        <div>
            <StyledOptionLabel isActive={isActive}>{option.label}</StyledOptionLabel>
            <StyledAmount color={option.color}>{option.amount}</StyledAmount>
        </div>
    </StyledTabWrapper>
);

const ExtensionDealTabs = ({ selectedTabs, setSelectedTabs, title, wonDealAmount, inProgressDealAmount }) => {
    const tabOptions = [
        {
            component: CustomTabContent(
                {
                    icon: <SVG src={toAbsoluteUrl("/media/svg/popleads/money_bag.svg")} />,
                    value: "won",
                    label: "WON Deal",
                    amount: (
                        <FormattedNumber
                            style="currency"
                            currency="usd"
                            minimumFractionDigits={0}
                            maximumFractionDigits={2}
                            value={wonDealAmount}
                        />
                    ),
                    progress: 100,
                    color: "#00856C",
                },
                selectedTabs === "Won"
            ),
            value: "Won",
        },
        {
            component: CustomTabContent(
                {
                    icon: <SVG src={toAbsoluteUrl("/media/svg/popleads/money-bag-hour.svg")} />,
                    value: "inProgress",
                    label: "In Progress",
                    amount: (
                        <FormattedNumber
                            style="currency"
                            currency="usd"
                            minimumFractionDigits={0}
                            maximumFractionDigits={2}
                            value={inProgressDealAmount}
                        />
                    ),
                    progress: 40,
                    color: "#8C0ED9",
                },
                selectedTabs === "In Progress"
            ),
            value: "In Progress",
        },
    ];
    return (
        <StyledContainer>
            <Tabs
                selected={selectedTabs}
                setSelected={setSelectedTabs}
                options={tabOptions}
                renderCustomContent={CustomTabContent}
            />
        </StyledContainer>
    );
};

export default ExtensionDealTabs;
