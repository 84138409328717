import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { colors } from "app/popleads/components/ui-kit/colors";
import RichTextEditor from "app/popleads/components/ui-kit/RichTextEditor";
import useProfileNoteActions from "app/popleads/hooks/useProfileNoteActions";
import { useExtensionContext } from "../ExtensionContext";
import { ExtensionBody, ExtensionSmall } from "app/popleads/components/ui-kit/Text";
import { useDropzone } from "react-dropzone";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "_metronic/_helpers";
import { decideFileType } from "../../activities/utils";

export const StyledNoteContent = styled.div`
    color: ${({ variant }) => (variant ? colors[variant][800] : colors.neutral[800])};
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.17px;
    line-height: 21px;
    overflow-wrap: anywhere;
    ${({ variant }) => variant !== "warning" && "cursor: pointer;"}

    & > p {
        margin: 0;
    }

    & i {
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
        letter-spacing: inherit;
        line-height: inherit;
    }
`;

const StyledContentWrapper = styled.div``;

const StyledDragAndDrop = styled.div`
    padding: 8px 16px;
    fons-size: 14px;
    background-color: #f6f8f9;
    border: 1px dashed #c2cccf;
    border-radius: 8px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    gap: 16px;
`;

const FileItem = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 4px;
    & > span {
        color: #8b999f;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
    }
`;

const ExtensionNote = ({ note }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [newNote, setNewNote] = useState(note.metin);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const wrapperRef = useRef(null);
    const { profile } = useExtensionContext();
    const { updateNote } = useProfileNoteActions();

    const { getRootProps, getInputProps, open } = useDropzone({
        noClick: true,
        noKeyboard: true,
        onDrop: (acceptedFiles) => {
            setSelectedFiles((prevFiles) =>
                [...prevFiles, ...acceptedFiles].map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            );
        },
    });

    const editorConfig = {
        toolbar: ["bold", "italic", "bulletedList", "numberedList"],
        placeholder: "Type your note",
        // extraPlugins: [uploadPlugin],
        startupFocus: true,
        mention: {
            feeds: [
                {
                    marker: "@",
                    feed: ["@Barney", "@Lily", "@Marry Ann", "@Marshall", "@Robin", "@Ted"],
                },
            ],
        },
        link: {
            addTargetToExternalLinks: true,
        },
    };

    const handleSubmit = async () => {
        const result = await updateNote({ profil: profile.id, note: note.id, text: newNote });
        if (result.error) {
            setNewNote(note.metin);
            return;
        }
        // setNote("");
        // setSelectedFiles([]);
        // addProfileNote(result.data.not);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            const editor_balloon_panel = document.querySelector(".ck-balloon-rotator__content");
            if (
                isEditing &&
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target) &&
                !editor_balloon_panel?.contains(event.target)
            ) {
                if (!newNote || newNote === note.metin) {
                    setNewNote(note.metin); // değer değişmediyse orijinal değere geri dön
                    setIsEditing(false);
                    return;
                }
                handleSubmit();
                setIsEditing(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isEditing, newNote]);

    return !isEditing ? (
        <StyledNoteContent
            variant={note.tip === "otomatik" && "warning"}
            dangerouslySetInnerHTML={{ __html: newNote }}
            onClick={() => {
                if (note.tip === "otomatik") return;
                setIsEditing(true);
            }}
        />
    ) : (
        <StyledContentWrapper ref={wrapperRef}>
            <RichTextEditor
                minHeight={"40px"}
                maxHeight={"130px"}
                data={newNote}
                config={editorConfig}
                onChange={(event, editor) => {
                    const editorData = editor.getData();
                    setNewNote(editorData);
                }}
                onReady={(editor) => {
                    editor.focus();
                }}
            />
            <StyledDragAndDrop {...getRootProps()}>
                <input {...getInputProps()} />
                <span className="material-symbols-outlined">cloud_upload</span>
                <div>
                    {selectedFiles &&
                        selectedFiles.length > 0 &&
                        selectedFiles.map((file, index) => (
                            <FileItem key={index}>
                                <SVG src={toAbsoluteUrl(decideFileType(file?.name))} />
                                <span>{file.name}</span>
                                <span
                                    className="material-symbols-outlined"
                                    style={{ fontSize: "20px", cursor: "pointer" }}
                                    onClick={() => {
                                        setSelectedFiles(selectedFiles.filter((_, i) => i !== index));
                                    }}
                                >
                                    close
                                </span>
                            </FileItem>
                        ))}
                    <div>
                        <ExtensionBody>Drag and Drop files here or</ExtensionBody>{" "}
                        <ExtensionBody
                            style={{ color: "#0085BE", textDecoration: "underline", cursor: "pointer" }}
                            onClick={open}
                        >
                            browse computer
                        </ExtensionBody>
                    </div>
                    <ExtensionSmall style={{ color: "#53676E" }}>
                        File supported: DOC, XLS, TXT, PDF, JPEG, PNG
                    </ExtensionSmall>
                </div>
            </StyledDragAndDrop>
        </StyledContentWrapper>
    );
};

export default ExtensionNote;
