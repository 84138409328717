import RangeInput from "app/popleads/components/ui-kit/RangeInput";
import React, { useState } from "react";
import { connect } from "react-redux";

function RangeInputComponent() {
    const [selected, setSelected] = useState(50);

    return (
        <div className="card card-custom mt-5">
            <div className="card-header">
                <h3 className="card-title">Range Input</h3>
            </div>
            <div className="card-body">
                <RangeInput
                    selected={selected}
                    setSelected={setSelected}
                    options={{
                        min: 25,
                        max: 75,
                        step: 25,
                    }}
                />

                <div className="mt-5">
                    <h3>Selected: {selected}%</h3>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RangeInputComponent);
