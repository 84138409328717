import React, { useImperativeHandle, forwardRef } from "react";
import styled from "styled-components";
import ExtensionInputGroup from "./ExtensionInputGroup";
import Options from "app/popleads/components/ui-kit/Options";
import ExtensionNewInhouseDeal from "./ExtensionNewInhouseDeal";
import { useExtensionContext } from "../ExtensionContext";
import { campaignTypesOptions } from "../utils/constants";
import ExtensionNewPublicDeal from "./ExtensionNewPublicDeal";
import ExtensionConsultingDeal from "./ExtensionConsultingDeal";
import { colors } from "app/popleads/components/ui-kit/colors";
import useCompanyData from "app/popleads/hooks/useCompanyData";
import { toast } from "react-toastify";

export const StyledLabel = styled.label`
    display: block;
    font-size: 12px;
    color: ${colors.neutral[600]};
    margin-bottom: 0px;

    ${(props) =>
        props.required &&
        `
    &:after {
      content: '*';
      color: #ff4d4f;
      margin-left: 4px;
    }
  `}

    ${(props) =>
        props.disabled &&
        `
    color: #999;
    cursor: not-allowed;
  `}
`;

const ExtensionNewDeal = forwardRef((props, ref) => {
    const { setDealObject, dealObject } = useExtensionContext();
    const { companyUsers } = useCompanyData();

    const statusOptions = [
        { label: "WON", value: "WON", activeVariant: "success" },
        { label: "Lost", value: "Lost", activeVariant: "neutral" },
        { label: "Cancelled", value: "Cancelled", activeVariant: "neutral" },
    ];

    const validateField = (fieldName, value) => {
        switch (fieldName) {
            case "amount":
                if (!value) {
                    toast.warning("You need to enter the amount first");
                    return false;
                }
                if (isNaN(value) || value <= 0) {
                    toast.warning("Amount must be a positive number");
                    return false;
                }
                break;
            case "campaign":
                if (
                    !value &&
                    [
                        "Public",
                        "LVT Public",
                        "Webinar",
                        "Self-Paced",
                        "Mentoring",
                        "Departmental",
                        "Private",
                        "Online",
                        "CH",
                        "Business Trip",
                        "Classroom/Online",
                        "Public-ExEd",
                    ].includes(dealObject.campaignType)
                ) {
                    toast.warning("You need to enter the campaign first");
                    return false;
                }
                break;
            case "closingDate":
                if (!value && ["WON", "Lost", "Cancelled"].includes(dealObject.status)) {
                    toast.warning("Please choose a closing date");
                    return false;
                }
                break;
            case "type":
                if (!value && dealObject.status === "WON") {
                    toast.warning("You need to enter the type first");
                    return false;
                }
                break;
            case "channelType":
                if (!value && ["Marketing", "Marketing Cross Sale"].includes(dealObject.type)) {
                    toast.warning("Please select marketing channel");
                    return false;
                }
                break;
            case "certificateName":
            case "certificateSurname":
                if (
                    !value &&
                    ["Public", "LVT Public"].includes(dealObject.campaignType) &&
                    dealObject.status === "WON"
                ) {
                    toast.warning(
                        `Please provide a certificate ${fieldName === "certificateName" ? "name" : "surname"}`
                    );
                    return false;
                }
                break;
            default:
                return true;
        }
        return true;
    };

    const validateAllFields = () => {
        // Campaign Type kontrolü
        if (!dealObject.campaignType) {
            toast.warning("Please select a campaign type");
            return false;
        }

        // Status kontrolü
        if (!dealObject.status) {
            toast.warning("Please select a status");
            return false;
        }

        // Amount kontrolü
        if (!validateField("amount", dealObject.amount)) {
            return false;
        }

        // Campaign Name kontrolü (Public ve benzeri tipler için)
        if (!validateField("campaign", dealObject.campaign)) {
            return false;
        }

        // Partner kontrolü
        if (!dealObject.partner) {
            toast.warning("Please select a partner");
            return false;
        }

        // WON durumu için özel kontroller
        if (dealObject.status === "WON") {
            if (!validateField("closingDate", dealObject.closingDate)) {
                return false;
            }

            if (!validateField("type", dealObject.type)) {
                return false;
            }

            if (!validateField("channelType", dealObject.channelType)) {
                return false;
            }

            // Public/LVT Public için sertifika kontrolleri
            if (["Public", "LVT Public"].includes(dealObject.campaignType)) {
                if (!validateField("certificateName", dealObject.certificateName)) {
                    return false;
                }
                if (!validateField("certificateSurname", dealObject.certificateSurname)) {
                    return false;
                }
            }
        }

        // Lost veya Cancelled durumu için özel kontroller
        if (["Lost", "Cancelled"].includes(dealObject.status)) {
            if (!validateField("closingDate", dealObject.closingDate)) {
                return false;
            }
        }

        return true;
    };

    // Dış bileşenlerin validateAllFields fonksiyonuna erişmesi için ref kullanıyoruz
    useImperativeHandle(ref, () => ({
        validateAllFields,
    }));

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <ExtensionInputGroup
                label="Select the Deal Potential"
                type="range"
                options={{ min: 25, max: 75, step: 25 }}
                onChange={(value) => {
                    if (dealObject.dealPotential === 75 && value !== 75 && dealObject.status === "WON") {
                        setDealObject({ ...dealObject, status: "", dealPotential: value });
                    } else {
                        setDealObject({ ...dealObject, dealPotential: value });
                    }
                }}
                value={dealObject.dealPotential}
            />

            <div className="d-flex align-items-center justify-content-between">
                <StyledLabel>Status</StyledLabel>
                <div className="d-flex" style={{ gap: "8px" }}>
                    <Options
                        options={dealObject.dealPotential <= 50 ? statusOptions.slice(1) : statusOptions}
                        selected={dealObject.status}
                        setSelected={(value) => {
                            if (value === dealObject.status) {
                                setDealObject({ ...dealObject, status: "" });
                            } else {
                                setDealObject({ ...dealObject, status: value });
                            }
                        }}
                    />
                </div>
            </div>

            <ExtensionInputGroup
                label="Campaign Type"
                type="select"
                options={campaignTypesOptions}
                onChange={(selected) => {
                    setDealObject({ ...dealObject, campaignType: selected.value });
                }}
                value={
                    dealObject.campaignType ? { label: dealObject.campaignType, value: dealObject.campaignType } : ""
                }
            />

            {(dealObject.campaignType === "Public" ||
                dealObject.campaignType === "Webinar" ||
                dealObject.campaignType === "LVT Public" ||
                dealObject.campaignType === "Self-Paced" ||
                dealObject.campaignType === "Mentoring" ||
                dealObject.campaignType === "Departmental" ||
                dealObject.campaignType === "Private" ||
                dealObject.campaignType === "Online" ||
                dealObject.campaignType === "CH" ||
                dealObject.campaignType === "Business Trip" ||
                dealObject.campaignType === "Classroom/Online" ||
                dealObject.campaignType === "Public-ExEd") && (
                <ExtensionNewPublicDeal
                    dealObject={dealObject}
                    setDealObject={setDealObject}
                    companyUsers={companyUsers}
                    validateField={validateField}
                />
            )}
            {(dealObject.campaignType === "EdTech" ||
                dealObject.campaignType === "Consulting" ||
                dealObject.campaignType === "E-Learning" ||
                dealObject.campaignType === "Coaching") && (
                <ExtensionConsultingDeal
                    dealObject={dealObject}
                    setDealObject={setDealObject}
                    companyUsers={companyUsers}
                    validateField={validateField}
                />
            )}
            {(dealObject.campaignType === "In-house" || dealObject.campaignType === "LVT In-house") && (
                <ExtensionNewInhouseDeal
                    dealObject={dealObject}
                    setDealObject={setDealObject}
                    companyUsers={companyUsers}
                    validateField={validateField}
                />
            )}
        </div>
    );
});

export default ExtensionNewDeal;
