import { colors } from "app/popleads/components/ui-kit/colors";
import InputWithIcon from "app/popleads/components/ui-kit/Input";
import { post } from "app/popleads/networking/RequestService";
import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import styled from "styled-components";

import moment from "moment";
import { StyledLabel } from "./ExtensionInputGroup";

const SearchContainer = styled.div`
    position: relative;
    width: 100%;
`;

const ResultsList = styled.div`
    border: 1px solid #ccc;
    border-radius: 8px;
    background: #fff;
    padding: 15px;
    position: absolute;
    top: 65px;
    width: 100%;
    z-index: 999;
    display: ${(props) => (props.isOpen ? "block" : "none")};
    max-height: 300px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
`;

const ResultItem = styled.div`
    margin-bottom: 5px;
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
        background-color: #f1f1f1;
    }

    &:last-child {
        margin-bottom: 0;
    }
`;

const ResultText = styled.div`
    display: flex;
    align-items: center;
    max-width: 100%;
    justify-content: space-between;
`;

const Name = styled.div`
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.17px;
    margin-bottom: 8px;
    color: ${colors.primary[900]};
`;

const Info = styled.div`
    font-size: 12px;
    color: ${colors.neutral[600]};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const DateInfo = styled.div`
    font-size: 12px;
    color: ${colors.neutral[500]};
    margin-left: auto;
`;

export default function ExtensionCampaignSearchInput({ optionClick }) {
    const [searchText, setSearchText] = useState("");
    const [filteredResults, setFilteredResults] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const wrapperRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setIsOpen(false);
                // clearSearch();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleSearch = async (e) => {
        const value = e.target.value;
        setSearchText(value);

        let requestObj = {
            fn: "etiket_ara",
            kelime: value,
            dealCampaigns: 1,
            inputName: "olanak_etiket",
        };

        try {
            setLoading(true);
            const response = await post(requestObj);

            if (response.status === 200) {
                // API'den gelen sonuçları direkt olarak kullan
                setFilteredResults(response.data.liste || []);
                setLoading(false);
                setIsOpen(true);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const formatDate = (date) => {
        if (!date) {
            return moment().format("DD MMM YYYY");
        }
        return moment(date).format("DD MMM YYYY");
    };

    const handleResultClick = (result) => {
        setSearchText(result.ad);
        setIsOpen(false);
        optionClick(result);
    };

    const clearSearch = () => {
        setSearchText("");
        setFilteredResults([]);
        setIsOpen(false);
    };

    return (
        <SearchContainer ref={wrapperRef}>
            <StyledLabel>Campaign Name</StyledLabel>
            <InputWithIcon
                placeholder="Search Campaigns"
                type={"text"}
                leftIcon={"search"}
                value={searchText}
                onChange={handleSearch}
                disabled={loading}
                rightIcon={
                    searchText.length > 0 && loading ? (
                        <Spinner animation="border" variant="primary" size="sm" />
                    ) : searchText.length > 0 && !loading ? (
                        "x"
                    ) : null
                }
                rightIconClick={clearSearch}
                onFocus={() => setIsOpen(filteredResults.length > 0)}
                onBlur={() => setTimeout(() => setIsOpen(false), 200)}
            />

            <ResultsList isOpen={isOpen}>
                {filteredResults.map((result, index) => (
                    <ResultItem key={index} onClick={() => handleResultClick(result)}>
                        <Name>{result.ad}</Name>
                        <ResultText>
                            <Info>{result.beklenenGelir ? `$${result.beklenenGelir}` : "-"}</Info>
                            <DateInfo>{formatDate(result.basTarih)}</DateInfo>
                        </ResultText>
                    </ResultItem>
                ))}
                {filteredResults.length === 0 && searchText && <div>Result not found.</div>}
            </ResultsList>
        </SearchContainer>
    );
}
