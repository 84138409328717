import React, { useState } from "react";
import styled from "styled-components";
import ButtonWithText from "app/popleads/components/ui-kit/Button";
import RichTextEditor from "app/popleads/components/ui-kit/RichTextEditor";
import { useDropzone } from "react-dropzone";
import { colors } from "app/popleads/components/ui-kit/colors";
import { toAbsoluteUrl } from "_metronic/_helpers";
import { decideFileType } from "../../activities/utils";
import SVG from "react-inlinesvg";
import { useExtensionContext } from "../ExtensionContext";
import useProfileNoteActions from "app/popleads/hooks/useProfileNoteActions";

const StyledContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 8px 12px;
    background-color: ${colors.white};
    border-top: 1px solid ${colors.neutral[100]};
`;

const StickyContainer = styled.div`
    max-width: 400px;
    position: absolute;
    bottom: 56px;
    border-radius: 8px;
    border 1px solid #DEE5E7;
    background-color: #FFFFFF;
    z-index: 1;
`;

const FileItemsWrapper = styled.div`
    max-height: 114px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin: 8px 0;
    padding: 1px 8px;
    box-sizing: border-box;
    overflow-y: auto;

    /* General scrollbar style */
    &::-webkit-scrollbar {
        width: 9px; /* Width of the scrollbar */
    }

    /* Scrollbar thumb style */
    &::-webkit-scrollbar-thumb {
        background-color: ${colors.neutral[300]}; /* Color of the scrollbar thumb */
        border-radius: 9px; /* Rounded corners */
        border: 3px solid transparent;
        background-clip: content-box;
        min-height: 32px;
    }

    /* Thumb color on hover */
    &::-webkit-scrollbar-thumb:hover {
        background-color: ${colors.neutral[500]};
    }

    /* Scrollbar corner style (if any) */
    &::-webkit-scrollbar-corner {
        background-color: ${colors.neutral[100]};
    }
`;

const FileItem = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    & > span {
        color: #8b999f;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
    }
`;

const ExtensionNotesFooter = () => {
    const { profile, addProfileNote } = useExtensionContext();
    const [note, setNote] = useState("");
    const [selectedFiles, setSelectedFiles] = useState([]);
    const { createNote } = useProfileNoteActions();

    const { getInputProps, open } = useDropzone({
        noClick: true,
        noKeyboard: true,
        onDrop: (acceptedFiles) => {
            setSelectedFiles((prevFiles) =>
                [...prevFiles, ...acceptedFiles].map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            );
        },
    });

    // function uploadAdapter(loader) {
    //     return {
    //         upload: () => {
    //             return new Promise((resolve, reject) => {
    //                 const body = new FormData();
    //                 loader.file.then((file) => {
    //                     body.append("uploadImg", file);
    //                     setSelectedFiles([...selectedFiles, file]);
    //                 });
    //             });
    //         },
    //     };
    // }

    // function uploadPlugin(editor) {
    //     editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    //         return uploadAdapter(loader);
    //     };
    // }

    const editorConfig = {
        toolbar: ["bold", "italic", "bulletedList", "numberedList"],
        placeholder: "Type your note",
        // extraPlugins: [uploadPlugin],
        mention: {
            feeds: [
                {
                    marker: "@",
                    feed: ["@Barney", "@Lily", "@Marry Ann", "@Marshall", "@Robin", "@Ted"],
                },
            ],
        },
        link: {
            addTargetToExternalLinks: true,
        },
    };

    const handleSubmit = async () => {
        let sendNoteObj = {
            fn: "notEkle",
            profil: profile.id,
            not: note,
        };

        if (selectedFiles && selectedFiles.length > 0) {
            const inputnames = [];
            selectedFiles.forEach((file, index) => {
                if (file && file.name) {
                    inputnames.push(`note${index}`);
                    sendNoteObj[`note${index}`] = file;
                }
            });
            if (inputnames.length > 0) {
                sendNoteObj.inputName = inputnames;
            }
        }

        const result = await createNote(sendNoteObj);

        if (result.error) return;

        setNote("");
        setSelectedFiles([]);
        addProfileNote(result.data.not);
    };

    return (
        <>
            {selectedFiles.length > 0 && (
                <StickyContainer>
                    <FileItemsWrapper>
                        {selectedFiles.map((file, index) => (
                            <FileItem key={index}>
                                <SVG src={toAbsoluteUrl(decideFileType(file?.name))} />
                                <span>{file.name}</span>
                                <span
                                    className="material-symbols-outlined"
                                    style={{ fontSize: "20px", cursor: "pointer" }}
                                    onClick={() => {
                                        setSelectedFiles(selectedFiles.filter((_, i) => i !== index));
                                    }}
                                >
                                    close
                                </span>
                            </FileItem>
                        ))}
                    </FileItemsWrapper>
                </StickyContainer>
            )}
            <StyledContainer>
                <RichTextEditor
                    width={"calc(100% - 88px)"}
                    minHeight={"40px"}
                    maxHeight={"68px"}
                    data={note}
                    config={editorConfig}
                    onChange={(event, editor) => {
                        const editorData = editor.getData();
                        setNote(editorData);
                    }}
                />
                <ButtonWithText
                    icon="attach_file"
                    iconType="material"
                    variant="neutral"
                    buttonStyle={"borderless"}
                    onClick={open}
                />
                <input {...getInputProps()} />
                <ButtonWithText
                    icon="send"
                    iconType="material"
                    variant="passive"
                    buttonStyle={"borderless"}
                    onClick={handleSubmit}
                />
            </StyledContainer>
        </>
    );
};

export default ExtensionNotesFooter;
