import React, { forwardRef } from "react";
import styled from "styled-components";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build-latest/build/ckeditor";
import { colors } from "./colors";

const EditorWrapper = styled.div`
    width: ${({ width }) => (width ? width : "100%")} !important;
    font-size: 14px !important;
    line-height: 1 !important;

    --ck-focus-ring: 1px solid ${colors.primary[900]};
    --ck-border-radius: 8px;
    --ck-spacing-large: 12px;
    --ck-inner-shadow: none;
    --ck-spacing-standard: 16px;

    .ck-blurred {
        border: 1px solid ${colors.neutral[200]} !important;
    }

    .ck-editor__editable {
        min-height: ${({ minHeight }) => (minHeight ? minHeight : "auto")} !important;
        max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : "auto")} !important;
        background-color: #fff !important;

        /* General scrollbar style */
        &::-webkit-scrollbar {
            width: 9px; /* Width of the scrollbar */
        }

        /* Scrollbar thumb style */
        &::-webkit-scrollbar-thumb {
            background-color: ${colors.neutral[500]}; /* Color of the scrollbar thumb */
            border-radius: 9px; /* Rounded corners */
            border: 3px solid transparent;
            background-clip: content-box;
            min-height: 32px;
        }

        /* Thumb color on hover */
        &::-webkit-scrollbar-thumb:hover {
            background-color: ${colors.neutral[700]};
        }

        /* Scrollbar corner style (if any) */
        &::-webkit-scrollbar-corner {
            background-color: ${colors.neutral[100]};
        }
    }

    .ck-content i {
        font-size: inherit !important;
        color: inherit !important;
    }

    .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
        border: 1px solid ${colors.neutral[200]} !important;
        border-radius: 8px !important;
        box-shadow: none !important;
        padding: 12px 16px !important;
        display: flex !important;
        flex-direction: column;
    }

    .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline:focus {
        border-color: ${colors.primary[900]} !important;
    }

    .ck.ck-editor__editable_inline > :first-child {
        margin-top: 0 !important;
    }

    .ck.ck-editor__editable_inline > :last-child {
        margin-bottom: 0 !important;
    }

    .ck.ck-editor__editable_inline > p {
        margin: 0 !important;
    }
`;

const RichTextEditor = forwardRef(({ minHeight, maxHeight, width, config, ...props }, ref) => {
    const defaultConfig = {
        toolbar: ["bold", "italic", "underline", "bulletedList", "numberedList"],
        placeholder: "Type or paste your content here!",
        // extraPlugins: [uploadPlugin],
        // mention: {
        //     feeds: [
        //         {
        //             marker: "@",
        //             feed: ["@Barney", "@Lily", "@Marry Ann", "@Marshall", "@Robin", "@Ted"],
        //         },
        //     ],
        // },
        link: {
            addTargetToExternalLinks: true,
        },
    };

    return (
        <EditorWrapper minHeight={minHeight} maxHeight={maxHeight} width={width}>
            <CKEditor ref={ref} editor={Editor} config={config ? config : defaultConfig} {...props} />
        </EditorWrapper>
    );
});

export default RichTextEditor;
