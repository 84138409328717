import { colors } from "app/popleads/components/ui-kit/colors";
import React, { useEffect } from "react";
import styled from "styled-components";
import { useExtensionContext } from "../ExtensionContext";
import { createDealViewSideBar } from "../utils/deal-utils";

const List = styled.ul`
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    padding: 0;
    list-style: none;
    height: 100%;
    overflow-y: auto;
`;

const ListItem = styled.li`
    padding: 0 20px;
    line-height: 32px;
    color: ${({ color }) => color};
    &::before {
        content: "●";
        font-size: 1.2em;
        display: inline-block;
        line-height: 12px;
        padding-right: 14px;
    }
`;

const BulletlessListItem = styled.li`
    padding: 0 20px;
    line-height: 32px;
    cursor: pointer;
    color: ${colors.neutral[600]};
    background-color: ${({ isActive }) => (isActive ? colors.neutral[100] : "transparent")};
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:hover {
        background-color: ${colors.neutral[100]};
    }
`;

const DealName = styled.div`
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const DealDurum = styled.div`
    color: ${({ color }) => color};
    width: 30px;
    margin-left: 8px;
`;

const ExtensionDealSideBar = ({ isActive, setIsActive, ...props }) => {
    const { profile } = useExtensionContext();
    const items = createDealViewSideBar(profile?.olanak);
    const textColors = ["#00856A", "#8C0ED9", "#6F8086", "#E76969"];

    useEffect(() => {
        if (!isActive && items?.length > 0 && items[0]?.deals?.length > 0) {
            setIsActive(items[0].deals[0].name);
        }
    }, [items, isActive, setIsActive]);

    return (
        <List as={"nav"} {...props}>
            {items.map((item, index) => (
                <React.Fragment key={index}>
                    {item.deals.length > 0 && <ListItem color={textColors[index]}>{item.name}</ListItem>}
                    {item.deals.map((deal, index2) => (
                        <BulletlessListItem
                            key={index2}
                            style={{ marginBottom: item.deals.length === index2 + 1 && "16px" }}
                            isActive={isActive === `${item.name}_${index2}`}
                            onClick={() => {
                                setIsActive(deal.name);
                            }}
                        >
                            <DealName>{deal.name}</DealName>
                            {deal.durum && <DealDurum color={textColors[index]}>{deal.durum}</DealDurum>}
                        </BulletlessListItem>
                    ))}
                </React.Fragment>
            ))}
        </List>
    );
};

export default ExtensionDealSideBar;
