import { toAbsoluteUrl } from "_metronic/_helpers";
import ButtonWithText, { MaterialIcon } from "app/popleads/components/ui-kit/Button";
import ExtensionCard from "app/popleads/components/ui-kit/ExtensionCard";
import FeatherIcon from "feather-icons-react";
import React from "react";
import SVG from "react-inlinesvg";

const ExtensionCardComponent = () => {
    const cardContactItems = [
        {
            icon: (
                <FeatherIcon
                    strokeWidth="1px"
                    // data-testid={`feather-${leftIcon}`}
                    icon="book-open"
                    size="16"
                />
            ),
            title: "",
            content: "Accounting for Non-Accountants",
            rightContent: "",
            footer: "LVT Public | December 2024",
        },
        {
            icon: (
                <FeatherIcon
                    strokeWidth="1px"
                    // data-testid={`feather-${leftIcon}`}
                    icon="book-open"
                    size="16"
                />
            ),
            content: "AccNA Sharm El Sheikh November 24 M2",
            rightContent: "",
            footer: "LVT Public | December 2024",
        },
        {
            materialIcon: "auto_awesome",
            featherIcon: "",
            icon: (
                <MaterialIcon data-testid={`material-auto_awesome`} className="material-icons">
                    auto_awesome
                </MaterialIcon>
            ),
            title: "",
            content: "AccNA Sharm El Sheikh November 24 M2",
            rightContent: "",
            footer: "LVT Public | December 2024",
        },
        {
            materialIcon: "auto_awesome",
            featherIcon: "",
            icon: (
                <MaterialIcon data-testid={`material-auto_awesome`} className="material-icons">
                    auto_awesome
                </MaterialIcon>
            ),
            title: "",
            content: "AccNA Sharm El Sheikh November 24 M2",
            rightContent: "",
            footer: "LVT Public | December 2024",
        },
    ];
    const cardDealItems = [
        {
            icon: <SVG src={toAbsoluteUrl("/media/svg/popleads/money_bag.svg")} />,
            title: "Nov 21, 2024",
            content: "Certificate in Data Analysis & Reporting Using Power BI",
            rightContent: "$1,000",
            footer: "Altay Seyfula",
        },
        {
            title: "Nov 21, 2024",
            icon: <SVG src={toAbsoluteUrl("/media/svg/popleads/money_bag.svg")} />,
            content: "Certificate in Data Analysis & Reporting Using Power BI",
            rightContent: "$1,000",
            footer: "Altay Seyfula",
        },
        {
            icon: <SVG src={toAbsoluteUrl("/media/svg/popleads/money_bag.svg")} />,
            title: "Nov 21, 2024",
            content: "Certificate in Data Analysis & Reporting Using Power BI",
            rightContent: "$1,000",
            footer: "Altay Seyfula",
        },
        {
            icon: <SVG src={toAbsoluteUrl("/media/svg/popleads/money_bag.svg")} />,
            title: "Nov 21, 2024",
            content: "Certificate in Data Analysis & Reporting Using Power BI",
            rightContent: "$1,000",
            footer: "Altay Seyfula",
        },
    ];
    const cardNoteItems = [
        {
            title: "Nov 7, 2024 10:40",
            content: "Company updated from “Popleadss” to “Popleads”",
            rightContent: "",
            footer: "Created by Altay Seyfula",
        },
        {
            title: "Nov 7, 2024 10:40",
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent at maximus diam",
            rightContent: "",
            footer: "Created by Altay Seyfula",
        },
        {
            title: "Nov 7, 2024 10:40",
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent at maximus diam",
            rightContent: "",
            footer: "Created by Altay Seyfula",
        },
        {
            title: "Nov 7, 2024 10:40",
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent at maximus diam",
            rightContent: "",
            footer: "Created by Altay Seyfula",
        },
    ];
    const cardSingleCourse = [
        {
            title: "Public | AccNA Sharm El Sheikh November 24 M2",
            imgSrc: "https://c.popleads.com/l3rn-online/course/Audit-Regulation-and-Compliance.jpg",
            content: "Accounting for Non-Accountants",
            footer: "Nov 21-28, 2024 | Sharm El Sheikh, Egypt",
        },
    ];
    return (
        <div className="card card-custom mt-5">
            <div className="card-header">
                <h3 className="card-title">Card</h3>
            </div>
            <div className="card-body">
                <ExtensionCard
                    button={
                        <ButtonWithText
                            icon="remove"
                            iconType="material"
                            variant="danger"
                            size="circle"
                            type="default"
                        />
                    }
                    cardTitle={"Contact"}
                    cardItems={cardContactItems}
                />
                <div className="mt-5">
                    <ExtensionCard
                        button={
                            <ButtonWithText
                                icon="add"
                                iconType="material"
                                variant="primary"
                                size="circle"
                                type="default"
                            />
                        }
                        cardTitle={"Deals"}
                        cardItems={cardDealItems}
                    />
                </div>
                <div className="mt-5">
                    <ExtensionCard
                        button={
                            <ButtonWithText
                                icon="add"
                                iconType="material"
                                variant="primary"
                                size="circle"
                                type="default"
                            />
                        }
                        cardTitle={"Notes"}
                        cardItems={cardNoteItems}
                    />
                </div>
                <div className="mt-5">
                    <ExtensionCard
                        // button={<ButtonWithText icon="add" iconType="material" variant="circlePrimary" />}
                        cardTitle={"Courses"}
                        cardItems={cardSingleCourse}
                    />
                </div>
            </div>
        </div>
    );
};

export default ExtensionCardComponent;
